import {maxCharLimit} from '../../../../utils/rules/validation';
import {ModalFormFieldTypesFunc} from '../../../../utils/types';

const fields: ModalFormFieldTypesFunc = (t: any, initialValues: any) => [
	{
		name: 'cityId',
		label: t('fields.cites'),
		type: 'select',
		colProps: {
			lg: 24,
			xs: 24
		},
		style: {
			height: 50
		},
		dictionaryApiPath: 'cities'
	},
	{
		name: 'typeId',
		label: t('fields.type'),
		type: 'select',
		colProps: {
			lg: 24,
			xs: 24
		},
		style: {
			height: 50
		},
		dictionaryApiPath: 'complaints-types'
	},
	{
		name: 'title',
		label: t('fields.title'),
		type: 'text',
		colProps: {
			lg: 24,
			xs: 24
		},
		style: {
			height: 50
		},
		rules: [maxCharLimit(150, t)]
	},
	{
		name: 'description',
		label: t('fields.description'),
		type: 'textArea',
		colProps: {
			lg: 24,
			xs: 24
		}
	},
	{
		name: 'attachedFile',
		label: t('fields.file'),
		type: 'file',
		accept: '.jpg,.jpeg,.png,.pdf,.doc,.docx',
		isRequired: false,
		maxCount: 5,
		colProps: {
			lg: 24,
			xs: 24
		}
	}
];
export default fields;
