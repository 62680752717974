import Excel from 'exceljs';

//@ts-ignore
import {saveAs} from 'file-saver';

let exportData = async ({rows, columns}: any) => {
	let wb = new Excel.Workbook();
	const keysForMutate: any[] = [];
	let ws = wb.addWorksheet('data', {views: [{state: 'frozen', ySplit: 1}]});
	ws.columns = columns.map((el: any) => {
		if (el.xlsx) {
			keysForMutate.push({[el.dataIndex]: el.xlsx});
		}
		return {key: el.dataIndex, header: el.title, width: 28, style: {alignment: {horizontal: 'center'}}};
	});
	ws.getRow(1).border = {
		bottom: {style: 'thick', color: {argb: 'FF000000'}}
	};
	ws.getRow(1).height = 25;
	ws.getRow(1).alignment = {horizontal: 'center', vertical: 'middle', wrapText: true};

	ws.addRows(rows);

	wb.xlsx.writeBuffer().then(data => {
		const blob = new Blob([data], {type: 'application/octet-stream'});
		saveAs(blob, 'data.xlsx');
	});
};

export default exportData;
