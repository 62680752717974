import Excel from 'exceljs';
//@ts-ignore
import {saveAs} from 'file-saver';

let exportCSV = async ({rows, columns, selectedRowKeys}: any) => {
	let wb = new Excel.Workbook();
	const keysForMutate: any[] = [];
	let ws = wb.addWorksheet('data', {views: [{state: 'frozen', ySplit: 1}]});

	ws.columns = columns.map((el: any) => {
		if (el.xlsx) {
			keysForMutate.push({[el.dataIndex]: el.xlsx});
		}
		return {key: el.dataIndex, header: el.title, width: 28};
	});

	ws.getRow(1).border = {
		bottom: {style: 'thick', color: {argb: 'FF000000'}}
	};
	ws.getRow(1).font = {bold: true};
	ws.getRow(1).height = 25;
	ws.getRow(1).alignment = {horizontal: 'center', vertical: 'middle', wrapText: true};

	ws.addRows(rows);

	const utf8Bom = new Uint8Array([0xef, 0xbb, 0xbf]);
	// Convert worksheet to CSV
	const csvBuffer = await wb.csv.writeBuffer();
	const blob = new Blob([utf8Bom, csvBuffer], {type: 'text/csv;charset=utf-8;'});
	saveAs(blob, 'data.csv');
};

export default exportCSV;
