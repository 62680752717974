import {Button, Form, Modal} from 'antd';
import {NamePath} from 'antd/lib/form/interface';
import _ from 'lodash';
import {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {
	getUserThunk,
	setAuthModalVisible,
	setFormErrors,
	signInThunk,
	signUpThunk
} from '../../store/auth.slice';
import {getAuthFields, getDialogSize, getOkText, getTitle} from '../../utils/heplerFuncs/functions';
import {TranslationFuncType} from '../../utils/types';
import FormGroup from '../formItems/FormGroup';
import ReCAPTCHA from 'react-google-recaptcha';
import './styles.less';
import {useMediaQuery} from 'react-responsive';
import Toast from '../Toast';
import {AppDispatch} from '../../store';

export const validateMessages = (t: TranslationFuncType) => ({
	required: t('messages.error.requiredInputError'),
	types: {
		email: t('messages.error.emailInputError')
	}
});

interface ModalFormProps {
	visible: false | 'SIGN_IN' | 'SIGN_UP' | 'FOGOT_PASSWORD';
	cancelText?: string | 'noCancel';
	// selectedRowId?: number;
	// getFieldsValues?: (id: number) => Promise<any>;
	formInitialValues?: any;
	onFormValuesChange?: (
		resetFields: (fields?: NamePath[]) => void,
		changedValues: any,
		allValues: any,
		setFieldsValue: (values: any) => void
	) => void;
}

const AuthModalForm: FC<ModalFormProps> = ({
	visible,
	cancelText,
	formInitialValues,
	onFormValuesChange
}) => {
	const [form] = Form.useForm();
	const [isSuccess, setIsSuccess] = useState<boolean>(false);
	const dialogSize = getDialogSize(visible);
	const dispatch = useDispatch<AppDispatch>();
	const auth = useSelector(({auth}) => auth);
	const isMobile = useMediaQuery({maxWidth: 480});
	const {t} = useTranslation();

	const [captchaIsOk, setCaptchaIsOk] = useState(false);

	const onChange = (value: any) => {
		setCaptchaIsOk(true);
	};
	const handleModalClose = () => {
		setCaptchaIsOk(false);
		dispatch(setAuthModalVisible({visble: false}));
		setIsSuccess(false);
		form.resetFields();
	};

	const onValuesChange = (changedValues: any, allValues: any) => {
		if (!_.isEmpty(auth.formErrors)) {
			dispatch(setFormErrors({}));
		}
		onFormValuesChange &&
			onFormValuesChange(form.resetFields, changedValues, allValues, form.setFieldsValue);
	};

	const onSubmit = () => {
		form.validateFields()
			.then(values => {
				const newValues = _.cloneDeep(values);
				if (visible === 'SIGN_IN') {
					dispatch(signInThunk(newValues)).then(() => {
						localStorage.getItem('token') && dispatch(getUserThunk());
					});
				}
				if (visible === 'SIGN_UP') {
					form.submit();
					form.validateFields()
						.then(() => {
							captchaIsOk
								? dispatch(signUpThunk(newValues))
								: Toast({
										description: t('pages.contact.reports.errorFields.reCaptcha'),
										type: 'error',
										duration: 2.5,
										placement: 'bottom'
								  });
						})

						.catch(err => {
							const error = err.errorFields?.[0]?.name?.[0];

							if (error) {
								const errorField = document.querySelector(`[name="${error}"]`) as HTMLElement;
								errorField.scrollIntoView({
									behavior: 'smooth',
									block: 'center',
									inline: 'center'
								});
							}
						});
				}
				for (const key in newValues) {
					if (
						Object.prototype.hasOwnProperty.call(newValues, key) &&
						typeof newValues[key] === 'string'
					) {
						newValues[key] = newValues[key].trim();
					}

					if (
						Object.prototype.hasOwnProperty.call(newValues, key) &&
						newValues[key] === undefined
					) {
						newValues[key] = null;
					}
				}

				visible === 'FOGOT_PASSWORD' && setIsSuccess(true);
			})
			.catch(err => {
				const error = err.errorFields?.[0]?.name?.[0];

				if (error) {
					const errorField = document.querySelector(`[name="${error}"]`) as HTMLElement;
					errorField.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});
				}
			});
	};

	useEffect(() => {
		if (visible === false) {
			form.resetFields();
			setIsSuccess(false);
		}
	}, [visible, form]);

	useEffect(() => {
		if (formInitialValues) {
			form.setFieldsValue(formInitialValues);
		}
	}, [formInitialValues, form]);

	return visible ? (
		<Modal
			open={!!visible}
			className={`auth-modal ${visible}`}
			title={auth.modal.title ? auth.modal.title : getTitle(visible, t)}
			onCancel={auth.loading ? undefined : handleModalClose}
			maskClosable={true}
			style={{top: isMobile ? 0 : 120}}
			width={dialogSize}
			footer={[]}>
			<Form
				form={form}
				layout="vertical"
				validateMessages={validateMessages(t)}
				initialValues={formInitialValues}
				validateTrigger="onBlur"
				onValuesChange={onValuesChange}
				onFinish={onSubmit}>
				{!!visible && (
					<FormGroup
						tabFields={getAuthFields({
							visible,
							t,
							dispatch,
							isSuccess,
							isLoading: auth.loading
						})}
						formErrors={auth.formErrors}
						form={form}
					/>
				)}
				<div style={{display: 'flex', justifyContent: 'center', marginLeft: 15}}>
					{visible === 'SIGN_UP' && (
						<ReCAPTCHA sitekey="6LcRSIQpAAAAAE9WEsFMgM3HBkSTJozTavzzl8yW" onChange={onChange} />
					)}
				</div>
				<div className="ant-modal-footer">
					{visible === 'FOGOT_PASSWORD' && isSuccess ? (
						<div key="cancelForgot">
							<Button className="forgot-btn" size="large" onClick={handleModalClose}>
								{t('pages.account.ticketHoldersPage.actions.checkBalance.popup.okText')}
							</Button>
						</div>
					) : (
						<>
							<div key="cancel">
								{visible !== 'SIGN_IN' && !isSuccess && (
									<Button className="main-btn" size="large" onClick={handleModalClose}>
										{cancelText || t('actions.cancel')}
									</Button>
								)}
							</div>
							<div key="submit">
								<Button
									className="main-btn"
									size="large"
									type="primary"
									htmlType="submit"
									onSubmit={form.submit}
									disabled={auth.loading}
									loading={auth.loading}>
									{getOkText(visible, t)}
								</Button>
							</div>
						</>
					)}
				</div>
			</Form>
		</Modal>
	) : (
		<></>
	);
};

export default AuthModalForm;
