import {ModalFormFieldTypesFunc} from '../../../../utils/types';

const fields: ModalFormFieldTypesFunc = t => [
	{
		name: 'ticketName',
		label: t('pages.account.myTickets.filter.filterBy.ticketName'),
		type: 'text',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		},
		inputProps: {
			style: {height: '50px'} // Set the height to 20px
		}
	},
	{
		name: 'carrerType',
		label: t('pages.tickets.oneUse.ticketCard.storage'),
		type: 'select',
		colProps: {
			lg: 12,
			xs: 24
		},
		isRequired: false
	},
	{
		name: 'purchaseDateRange',
		label: t('pages.tickets.oneUse.ticketCard.purchaseDate'),
		type: 'dateRange',
		colProps: {
			lg: 12,
			xs: 24
		},
		isRequired: false
	},
	{
		name: 'verificationDateRange',
		label: t('pages.account.myTickets.filter.filterBy.validDate'),
		type: 'dateRange',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		}
	},
	{
		name: 'validStatus',
		label: t('pages.account.myTickets.filter.filterBy.valid.title'),
		type: 'select',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		},
		selectProps: {
			options: [
				{label: t('pages.account.myTickets.filter.filterBy.valid.options.valid'), value: 'valid'},
				{label: t('pages.account.myTickets.filter.filterBy.valid.options.invalid'), value: 'invalid'}
			]
		}
	}
];
export default fields;
