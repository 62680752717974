import {numberRules} from '../../../../../utils/rules/validation';
import {ModalFormFieldTypesFunc} from '../../../../../utils/types';

const transactionForm: ModalFormFieldTypesFunc = t => [
	{
		name: 'statusId',
		label: t('pages.account.partner.transactions.transferstatus'),
		type: 'select',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		},
		inputProps: {
			style: {height: '50px', border: 'none'}
		},
		dictionaryApiPath: 'transaction_statuses'
	},
	{
		name: 'routeNumber',
		label: t('pages.account.partner.transactions.routes'),
		type: 'select',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		},
		fetchOnDepValue: false,
		customApiFetch: {
			path: 'api/v1/routes/public',
			labelKey: val => val?.number,
			valueKey: 'number'
		}
	},
	{
		name: 'transportYellowLicensePlate',
		label: t('pages.account.partner.buses.titles'),
		type: 'select',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		},
		fetchOnDepValue: false,
		customApiFetch: {
			path: 'api/v1/partners/transports',
			labelKey: val => val?.yellowLicensePlate,
			valueKey: 'yellowLicensePlate'
		}
	},
	{
		name: 'transactionDate',
		isRequired: false,
		label: t('pages.account.partner.transactions.filter.date'),
		type: 'dateRange',
		colProps: {
			lg: 12,
			xs: 24
		}
	},
	{
		name: 'amount',
		label: t('pages.account.partner.transactions.filter.money'),
		type: 'text',
		rules: [numberRules(t)],

		isRequired: false,
		colProps: {
			lg: 24,
			xs: 24
		},
		style: {
			height: 50
		}
	},
	{
		name: 'holderTypeIds',
		label: t('pages.tickets.oneUse.ticketCard.storage'),
		type: 'select',
		mode: 'multiple',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		},
		dictionaryApiPath: 'ticket-holder-types'
	},
	{
		name: 'typeIds',
		label: 'Տրանզակցիայի տեսակ',
		type: 'select',
		isRequired: false,
		mode: 'multiple',

		colProps: {
			lg: 12,
			xs: 24
		},
		dictionaryApiPath: 'transaction-types'
	},
	{
		name: 'validatorSerialNumber',
		label: t('pages.account.partner.transactions.terminal'),
		type: 'select',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		},
		customApiFetch: {
			path: 'api/v1/partners/transactions',
			labelKey: val => val?.number,
			valueKey: 'validatorSerialNumber'
		}
	}
];
export default transactionForm;
