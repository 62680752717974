import {useDispatch, useSelector} from 'react-redux';
import DataExport from '../../../../Components/DataExport/DataExport';
import {IPassengerTickets} from '../../../../utils/types';
import DefaultWrapper from '../DefaultWrapper/DefaultWrapper';
import './MyTickets.less';
import OwnedTicket from './OwnedTicket/OwnedTicket';
import filter_fields from './filter.form';
import PagePagination from '../../../../Components/Pagination/PagePagination';
import {usePagination} from '../../../../utils/usePagination/usePagination';
import {useEffect} from 'react';
import {getMyTicketsThunk} from '../../../../store/account.userTickets.slice';
import {Empty, Spin} from 'antd';
import i18n from '../../../../utils/translate/i18';
import {useTranslation} from 'react-i18next';
import dayjs from 'dayjs';

const MyTickets = () => {
	let dispatch = useDispatch();
	let {t} = useTranslation();
	useEffect(() => {
		dispatch(getMyTicketsThunk());
	}, []);
	const myTickets = useSelector(({userTickets}) => userTickets.myTickets);
	const {loading, error} = useSelector((state: any) => state.userTickets.myTickets);
	const {currentItems, currentPage, goToPage} = usePagination(myTickets);

	const columns = [
		{dataIndex: 'holderType', title: t('pages.tickets.oneUse.ticketCard.storage')},
		{dataIndex: 'ticketType', title: t('pages.tickets.oneUse.ticketCard.ticketType')},

		{dataIndex: 'name', title: t('pages.account.myTickets.filter.filterBy.ticketName')},
		{dataIndex: 'price', title: t('pages.tickets.oneUse.ticketCard.price')},
		{dataIndex: 'tripsCount', title: t('pages.tickets.oneUse.ticketCard.tripCount')},
		{dataIndex: 'description', title: t('pages.account.myTickets.description')},
		{dataIndex: 'creationDate', title: t('pages.account.myTickets.filter.filterBy.purchaseDate')},
		{dataIndex: 'expirationDate', title: t('pages.account.myTickets.filter.filterBy.validDate')},
		{dataIndex: 'valid', title: t('pages.account.myTickets.filter.filterBy.valid.title')}
	];
	const rows = currentItems?.map((ticket: any) => {
		return {
			holderType: ticket.holderType[i18n.language],
			ticketType: ticket.type[i18n.language],

			name: ticket[
				`name${i18n.language.charAt(0).toUpperCase() + i18n.language.slice(1).toLowerCase()}`
			],
			tripsCount: ticket.tripsCount,
			price: ticket.price,
			description:
				ticket[
					`description${
						i18n.language.charAt(0).toUpperCase() + i18n.language.slice(1).toLowerCase()
					}`
				],
			creationDate: dayjs(ticket.createdAt).format('DD-MM-YYYY').toString(),
			expirationDate: dayjs(ticket.expirationDate).format('DD-MM-YYYY').toString(),
			valid: ticket.valid
				? t('pages.account.myTickets.filter.filterBy.valid.options.valid')
				: t('pages.account.myTickets.filter.filterBy.valid.options.invalid')
		};
	});

	return (
		<div className="my-tickets">
			<div className="my-tickets__container">
				<div className="my-tickets__content">
					<div className="my-tickets__row">
						<div className="my-tickets__top">
							<DataExport exportData={{rows, columns}} />
							<DefaultWrapper filteritems={filter_fields} />
						</div>

						<div className="my-tickets__owned">
							{typeof currentItems[0] === 'undefined' ? (
								<Empty />
							) : (
								currentItems?.map((el: IPassengerTickets) => (
									<OwnedTicket ticket={el} key={el.id} />
								))
							)}
						</div>
					</div>
				</div>
				<PagePagination
					currentPage={currentPage}
					totalItems={myTickets.length}
					onPageChange={goToPage}
				/>
			</div>
		</div>
	);
};

export default MyTickets;
