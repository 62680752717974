import forgotPass_fields from '../../Components/AuthModalForm/forms/ForgotPassword';
import signIn_fields from '../../Components/AuthModalForm/forms/SignIn.form';
import signUp_fields from '../../Components/AuthModalForm/forms/SignUp.form';
import i18n from '../translate/i18';
import {TranslationFuncType} from '../types';
import dayjs from 'dayjs';

const setUser = () => {
	let user = JSON.parse(localStorage.getItem('user') || '{}');
	const token = localStorage.getItem('token');
	if (user?.name && user?.email && user.roleId && token) {
		user = {...user, isAuthenticated: true};
	} else {
		user = {};
	}
	return user;
};

export const clearLocalStorage = () => {
	const lang = localStorage.getItem('language');
	localStorage.clear();
	localStorage.setItem('language', lang || 'hy');
};
const mutatedateRangeKey = (state: Record<string, any> | string, keys: string[] = ['createdAt']) => {
	let newData: Record<string, any> = {};
	const isStateString = typeof state === 'string';

	if (isStateString) {
		newData[keys[0]] = state; // If state is a string, assign it to the first key
	} else {
		newData = {...state};
	}

	keys.forEach(key => {
		if (Array.isArray(newData[key]) && newData[key]?.length) {
			newData[key] = `${dayjs(dayjs(newData[key][0]).format('YYYY-MM-DD'))
				.startOf('day')
				.toISOString()}_${dayjs(dayjs(newData[key][1]).format('YYYY-MM-DD'))
				.endOf('day')
				.toISOString()}`;
		}
	});

	return isStateString ? newData[keys[0]] : newData;
};

const helperFuncs = {setUser, clearLocalStorage, mutatedateRangeKey};

export default helperFuncs;

/* AUTH_NODAL */
export function getAuthFields({
	visible,
	t,
	dispatch,
	isSuccess,
	isLoading
}: {
	visible: false | 'SIGN_IN' | 'SIGN_UP' | 'FOGOT_PASSWORD';
	t: TranslationFuncType;
	dispatch: (data: any) => void;
	isSuccess?: boolean;
	isLoading?: boolean;
}) {
	switch (visible) {
		case 'SIGN_IN':
			return signIn_fields(t, dispatch, isLoading);
		case 'SIGN_UP':
			return signUp_fields(t, dispatch, isLoading);
		case 'FOGOT_PASSWORD':
			return forgotPass_fields(t, dispatch, isSuccess);
		default:
			return signIn_fields(t, dispatch);
	}
}

export function getTitle(val: false | 'SIGN_IN' | 'SIGN_UP' | 'FOGOT_PASSWORD', t: TranslationFuncType) {
	switch (val) {
		case 'SIGN_IN':
			return t('header.sign_in');
		case 'SIGN_UP':
			return t('header.sign_up');
		case 'FOGOT_PASSWORD':
			return t('header.passwordRecovery');
		default:
			return t('header.sign_in');
	}
}

export function getOkText(val: false | 'SIGN_IN' | 'SIGN_UP' | 'FOGOT_PASSWORD', t: TranslationFuncType) {
	switch (val) {
		case 'SIGN_IN':
			return t('actions.logIn');
		case 'SIGN_UP':
			return t('actions.register');
		case 'FOGOT_PASSWORD':
			return t('actions.confirm');
		default:
			return t('actions.save');
	}
}

export function getDialogSize(val: false | 'SIGN_IN' | 'SIGN_UP' | 'FOGOT_PASSWORD') {
	switch (val) {
		case 'SIGN_IN':
			return 600;
		case 'SIGN_UP':
			return 1000;
		case 'FOGOT_PASSWORD':
			return 600;
		default:
			return 800;
	}
}
export function getKeyByLang(key: string) {
	return key + i18n.language.charAt(0).toUpperCase() + i18n.language.slice(1);
}
