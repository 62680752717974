import './TransportCard.front.less';
import './TransportCard.back.less';
import QRCode from 'qrcode.react';
import React, {useRef, useEffect} from 'react';
import QRCodeStyling from 'qr-code-styling';
import logo from './../../assets/icons/card-front-logo.svg';
import nfcLogo from './../../assets/icons/nfc-logo.svg';
import './TransportCard.front.less';
import './TransportCard.back.less';
import busCardLogo from './../../assets/MyTicketCarrier/bus-card-logo.svg';
let TransportCardFront = () => {
	return (
		<div className="transportCard_Front">
			<div className="transportCard_Front__container">
				<div className="transportCard_Front__content">
					<div className="transportCard_Front__content-top">
						<div className="transportCard_Front__left">
							<div className="transportCard_Front__left-top">
								<div className="transportCard_Front__webpage-logo">
									<img src={logo} alt="" />
								</div>
								<div className="transportCard_Front__card-title">
									<p>ՈՒՂԵՏՈՄՍ</p>
								</div>
							</div>
							<div className="transportCard_Front__left-bottom">
								<div className="transportCard_Front__nfc-logo">
									<img src={nfcLogo} alt="" />
								</div>
							</div>
						</div>
						<div className="transportCard_Front__right transportCard_Front__bus-logo">
							<img src={busCardLogo} alt="" />
						</div>
					</div>
					<div className="transportCard_Front__content-bottom">
						<div className="transportCard_Front__road"></div>
					</div>
				</div>
			</div>
		</div>
	);
	// return <QRCode value={'gorkarapetan' || '-'} size={80} />;
};

export {TransportCardFront};

const TransportCardBack = ({number}: {number: string}) => {
	const qrCodeRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const qrCode = new QRCodeStyling({
			width: 50, // Adjust size as needed
			height: 50, // Adjust size as needed
			type: 'svg', // Render QR code as SVG

			data: number || 'YourQRCodeValue', // QR code data
			dotsOptions: {
				color: '#000000', // Black color for QR code dots
				type: 'square' // Rounded dots to match the style in the screenshot
			},
			cornersSquareOptions: {
				color: '#000000',
				// Green color for the corner squares
				type: 'square' // Extra rounded to match the style in the screenshot
			},
			cornersDotOptions: {
				color: '#00b894', // Black color for the dots inside corner squares
				type: 'square' // Square dots inside corner squares
			},
			backgroundOptions: {
				color: '#ffffff' // White background color
			}
		});

		if (qrCodeRef.current) {
			qrCode.append(qrCodeRef.current); // Append QR code to the DOM
		}
	}, [number]);

	return (
		<div className="transportCard_Back">
			<div className="transportCard_Back__container">
				<div className="transportCard_Back__content">
					<div className="transportCard_Back__content-custom-background">
						<div className="transportCard_Back__content-general">
							<div className="transportCard_Back__content-general-top">
								<div className="transportCard_Back__id">
									<p>{number}</p>
								</div>
								<div className="transportCard_Back__top-title">
									<p>
										Ուղետոմսը նախատեսված է Գյումրի և Վանաձոր համայնքներում ընդհանուր
										օգտագործման տրանսպորտով երթևեկելու համար
									</p>
								</div>
								<div className="transportCard_Back__top-info">
									<p>
										Տեղեկատվական կենտրոն՝ <span>eTransport.am</span>
									</p>
								</div>
							</div>
							<div className="transportCard_Back__content-general-bottom">
								<div className="transportCard_Back__qr">
									<div ref={qrCodeRef} className="qr-code-container"></div>
								</div>
								<div className="transportCard_Back__bottom-title">
									<p>Սույն ուղետոմսը վերականգնման կամ վերադարձի ենթակա չէ</p>
								</div>
							</div>
						</div>
					</div>
					<div className="transportCard_Back__content-right-feature"></div>
				</div>
			</div>
		</div>
	);
};

export {TransportCardBack};
