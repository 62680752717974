import {Button, Dropdown, Form} from 'antd';
import {Sliders} from 'phosphor-react';
import {useContext, useEffect, useState, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {validateMessages} from '../../../../Components/AuthModalForm';
import FormGroup from '../../../../Components/formItems/FormGroup';
import './DefaultWrapper.less';
import {useLocation} from 'react-router-dom';
import {AccountTabContext} from '../../../../utils/Context/AccountTabContext';
import {useSelector} from 'react-redux';

function DefaultWrapper({
	filteritems,
	applyFilters,
	defaultFilters
}: {
	filteritems: any;
	applyFilters?: any;
	defaultFilters?: any;
}) {
	const {t} = useTranslation();
	const location = useLocation();
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const accountTabs = useContext(AccountTabContext);
	const [form] = Form.useForm();
	const [formErrors, setFormErrors] = useState({});
	const addComplaintModal = useSelector(({complaints}) => complaints.addComplaintModal);
	const addNewHolderModal = useSelector(({ticketHolders}) => ticketHolders.addNewHolder);

	useEffect(() => {
		applyFilters && applyFilters(null);
	}, [location.pathname]);

	useEffect(() => {
		if (addComplaintModal || addNewHolderModal) {
			setDropdownOpen(false);
		}
	}, [addComplaintModal, addNewHolderModal]);

	useEffect(() => {
		setDropdownOpen(false);
	}, [location.pathname, location.search, accountTabs]);

	const FilterContainer = () => {
		const [form] = Form.useForm();

		useEffect(() => {
			if (defaultFilters) {
				form.setFieldsValue(defaultFilters);
			}
		}, [defaultFilters, form]);

		const onValuesChange = () => {};

		const onSubmit = (data: any) => {
			const filteredData = Object.fromEntries(
				Object.entries(data).filter(([_, value]) => value !== '')
			);
			applyFilters(filteredData);
			setDropdownOpen(false);
		};

		const resetForm = () => {
			form.resetFields();
			applyFilters(null);
			setDropdownOpen(false);
		};

		return (
			<div className="filter" onClick={e => e.stopPropagation()}>
				<div className="filter__header">
					<p>{t('actions.filter')}</p>
				</div>

				<Form
					form={form}
					layout="vertical"
					validateMessages={validateMessages(t)}
					onValuesChange={onValuesChange}
					onFinish={onSubmit}>
					<FormGroup tabFields={filteritems(t)} formErrors={formErrors} form={form} />
				</Form>
				<div className="default__wrapper__btn">
					<Button className="reset" style={{minWidth: 120}} onClick={resetForm} size="large">
						{t('pages.account.myTickets.buttons.reset')}
					</Button>
					<Button style={{minWidth: 120}} onClick={() => form.submit()} size="large">
						{t('pages.account.myTickets.buttons.confirm')}
					</Button>
				</div>
			</div>
		);
	};

	return (
		<div className="defaultwrapper__filter">
			<Dropdown
				destroyPopupOnHide
				dropdownRender={() => <FilterContainer />}
				trigger={['click']}
				placement="bottomRight"
				autoAdjustOverflow={false}
				autoFocus={false}
				open={dropdownOpen} // Control visibility with state
				onOpenChange={setDropdownOpen} // Handle opening/closing of the dropdown
			>
				<a onClick={e => e.preventDefault()}>
					<Button
						className="button-filter-images"
						size={'large'}
						onClick={() => setDropdownOpen(!dropdownOpen)}>
						<Sliders size={32} className="filter-images" />
					</Button>
				</a>
			</Dropdown>
		</div>
	);
}

export default DefaultWrapper;
