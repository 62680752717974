import {PlusOutlined} from '@ant-design/icons';
import {Button, Col, ColProps, Divider, Form, Row} from 'antd';
import {FormInstance} from 'antd/lib/form';
import moment from 'moment';
import {Trash} from 'phosphor-react';
import {useRef} from 'react';
import {ModalFormFieldTypes} from '../../../utils/types';
import CaseInput from '../CaseInput';
import './styles.less';
import dayjs from 'dayjs';

const defaultColProps = {
	xs: 24,
	lg: 8
};

const required = [{required: true}];

interface FormTitleTypes {
	label?: string;
	colProps: ColProps;
	field?: ModalFormFieldTypes;
}

function calcFieldRules(isRequired?: boolean, rules?: any) {
	const newRules = isRequired === false ? rules : [...(rules || []), ...required];

	return newRules;
}

function ltrim(str: any) {
	if (!str) return str;
	return str.replace(/^\s+/g, '');
}

const FormTitle = ({label, colProps, field}: FormTitleTypes) => {
	let hidden = false;
	if (field?.props?.hidden) {
		hidden = field.props.hidden as boolean;
	}
	return (
		<Col {...colProps} hidden={hidden}>
			<div className="form-group-title">{label}</div>
		</Col>
	);
};

interface FormItemProps {
	name?: string | (string | number)[];
	field: ModalFormFieldTypes;
	formErrors: any;
	parentName?: string;
	form?: FormInstance;
	index?: number;
	disabled?: boolean;
	dynemicLabel?: string;
	selectedRowId?: number;
	visible?: 'PARTNER' | 'USER' | 'ADD' | 'EDIT';
	updateTable?: () => void;
	forceSetStateForCRUDFormItem?: (values: any) => void;
}

const FormItem = ({
	name,
	field,
	formErrors,
	parentName,
	index = 0,
	disabled,
	form,
	dynemicLabel,
	selectedRowId,
	visible
}: FormItemProps) => {
	if (field.type === 'divider') {
		return (
			<Col xs={24}>
				<Divider style={{margin: '0 0 15px'}} />
			</Col>
		);
	}

	if (field.type === 'title') {
		return (
			<FormTitle
				label={field.label}
				colProps={field.colProps ? field.colProps : {xs: 24}}
				field={field}
			/>
		);
	}

	if (field.type === 'newLine') {
		return <Col xs={24} />;
	}
	if (field.type === 'newLine_height35') {
		return <Col xs={24} style={{height: 35}} />;
	}

	const {
		depFieldKey,
		depValue,
		depOptionsKey,
		depOptions,
		fetchOnDepValue,
		colProps = defaultColProps,
		formItemProps,
		isRequired,
		isRequiredDep,
		label,
		disabledAt,
		isDisabled,
		disabledOnDep,
		tooltip,
		...props
	} = field;

	let newFormItems = formItemProps;

	if (field.type === 'text' || field.type === 'textArea') {
		newFormItems = {
			...newFormItems,
			getValueFromEvent: (val: any) => {
				const simbols = '~!@#$%^&*()_+=<>?,./\\{}[]:";-№՝|`₹₽₸₴€₪¥圓£ƒ₤ł';
				let startVal = val.target.value;
				if (val.target.value.length === 1 && simbols.includes(startVal)) {
					startVal = '';
				}
				return ltrim(startVal);
			}
		};
	} else if (field.type === 'file') {
		newFormItems = {
			...newFormItems,
			getValueFromEvent: (val: any) =>
				typeof val === 'string' ? val : val.fileList.length ? val.file : undefined
		};
	} else if (field.type === 'date') {
		newFormItems = {
			...newFormItems,
			getValueFromEvent: val => {
				if (!val) return null;

				if (props.datePickerProps?.format === 'YYYY') {
					return dayjs(val).format('YYYY');
				} else if (props.datePickerProps?.format === 'HH:mm') {
					return dayjs(val, 'HH:mm').format('HH:mm');
				} else if (props.datePickerProps?.format === 'DD-MM-YYYY HH:mm') {
					return dayjs(val).format('YYYY-MM-DD HH:mm');
				} else {
					return dayjs(val).format('YYYY-MM-DD');
				}
			}
		};
	}

	let newLabel = dynemicLabel || label;

	const helpStatus =
		name &&
		(Array.isArray(name)
			? parentName &&
			  formErrors[parentName] &&
			  formErrors[parentName][name[0]] &&
			  formErrors[parentName][name[0]][name[1]]
			: formErrors[name as string]);

	if (field.type === 'customElement' && !depFieldKey) {
		return <Col {...colProps}>{field.component && field.component(selectedRowId)}</Col>;
	}

	return depFieldKey ? (
		<Form.Item
			noStyle
			shouldUpdate={(prevValues, currentValues) => {
				const depFieldKeyArr = Array.isArray(depFieldKey) ? depFieldKey : [depFieldKey];

				let update = false;
				if (parentName) {
					depFieldKeyArr.forEach(key => {
						if (
							(prevValues[parentName][index] && prevValues[parentName][index][key]) !==
							(currentValues[parentName][index] && currentValues[parentName][index][key])
						) {
							update = true;
						}
					});
				} else {
					depFieldKeyArr.forEach(key => {
						if (prevValues[key] !== currentValues[key]) update = true;
					});
				}

				return update;
			}}>
			{({getFieldValue}: FormInstance) => {
				let showField = false;
				let isFieldRequired = isRequired;
				let dependencies = undefined;

				let depFieldValue = '';
				let depFieldValueArr = {} as any;
				if (Array.isArray(depFieldKey)) {
					const depStatus = depFieldKey.every((key, i) => {
						const fieldValue = parentName
							? getFieldValue([parentName, index, key])
							: getFieldValue(key);

						if (fetchOnDepValue === true && fieldValue) {
							depFieldValueArr[key] = fieldValue;
						} else if (fetchOnDepValue === key && fieldValue) {
							depFieldValue = fieldValue;
						}

						if (
							Array.isArray(isRequired) &&
							Array.isArray(isRequiredDep) &&
							!!isRequiredDep.length
						) {
							const values = isRequired.reduce((unique: boolean[], el: any, idx: number) => {
								if (depFieldValueArr[isRequiredDep[idx]])
									unique.push(el(depFieldValueArr[isRequiredDep[idx]]));
								return unique;
							}, []);

							isFieldRequired = values.includes(true);
							dependencies = parentName ? [[parentName, index, key]] : [key];
						}

						if (typeof isRequired === 'function' && isRequiredDep === key) {
							isFieldRequired = isRequired(fieldValue, getFieldValue);
							dependencies = parentName ? [[parentName, index, key]] : [key];
						}

						return (
							fieldValue === (Array.isArray(depValue) && depValue[i]) ||
							(Array.isArray(depValue) &&
								typeof depValue[i] === 'function' &&
								(depValue[i] as Function)(fieldValue)) ||
							(Array.isArray(fieldValue) &&
								fieldValue.some(
									(val: any) => val === (Array.isArray(depValue) && depValue[i])
								))
						);
					});

					if (depStatus) showField = true;
				} else {
					const fieldValue = parentName
						? getFieldValue([parentName, index, depFieldKey])
						: getFieldValue(depFieldKey);

					if (fetchOnDepValue && fieldValue) {
						depFieldValue = fieldValue;
					}

					if (typeof isRequired === 'function') {
						isFieldRequired = isRequired(fieldValue, getFieldValue);
						dependencies = parentName ? [[parentName, index, depFieldKey]] : [depFieldKey];
					}

					if (
						(fetchOnDepValue && fieldValue && fieldValue?.length !== 0) ||
						(typeof depValue === 'function' && depValue(fieldValue)) ||
						(depValue && fieldValue === depValue) ||
						(Array.isArray(fieldValue) && fieldValue.some((val: any) => val === depValue))
					) {
						showField = true;
					}
				}
				const fieldOptionValue =
					parentName && depOptionsKey
						? getFieldValue([parentName, index, depOptionsKey])
						: depOptionsKey && getFieldValue(depOptionsKey);

				if (field.type === 'customElement' && showField) {
					return (
						<Col {...colProps}>{field.component && field.component(selectedRowId, disabled)}</Col>
					);
				}

				const selectAditionalProp =
					field.type === 'select'
						? {
								fetchId: depFieldValue,
								fetchParams: depFieldValueArr,
								fieldValue: depOptionsKey ? fieldOptionValue : undefined,
								depOptions: depOptions ? depOptions : undefined,
								depFieldKey
						  }
						: {};

				return showField || disabledOnDep ? (
					<Col {...colProps}>
						<Form.Item
							label={newLabel}
							name={name}
							rules={calcFieldRules(isFieldRequired as boolean, field.rules)}
							help={helpStatus}
							validateStatus={!!helpStatus ? 'error' : undefined}
							dependencies={dependencies}
							style={{marginBottom: field.type === 'checkbox' ? 0 : 24}}
							{...newFormItems}>
							<CaseInput
								getFieldValue={getFieldValue}
								disabled={disabled || (!showField && disabledOnDep)}
								{...props}
								{...selectAditionalProp}
							/>
						</Form.Item>
					</Col>
				) : null;
			}}
		</Form.Item>
	) : (
		<Col {...colProps}>
			<Form.Item
				label={newLabel}
				name={name}
				rules={calcFieldRules(isRequired as boolean, field.rules)}
				help={helpStatus}
				validateStatus={!!helpStatus ? 'error' : undefined}
				style={{marginBottom: field.type === 'checkbox' ? 0 : 24}}
				{...newFormItems}>
				<CaseInput disabled={disabled} {...props} className={field.className || null} />
			</Form.Item>
		</Col>
	);
};

const FormGroup = ({
	tabFields,
	formErrors,
	visible,
	formInitialValues,
	selectedRowId,
	form
}: {
	tabFields: ModalFormFieldTypes[];
	formErrors: any;
	visible?: 'USER' | 'PARTNER' | 'ADD' | 'EDIT';
	formInitialValues?: any;
	selectedRowId?: number;
	form?: FormInstance;
}) => {
	const firstField = useRef(false);
	return (
		<Row gutter={16}>
			{tabFields?.map((field, i) => {
				const {
					name,
					colProps = defaultColProps,
					visibility = ['USER', 'PARTNER', 'ADD', 'EDIT'],
					groupTitle,
					className,
					minimalItem = 0,
					disabledAt,
					disableDynamicActions,
					disableDivider,
					isDisabled
				} = field;

				if (visible && !visibility.includes(visible)) return null;
				if (field.type === 'formGroup') {
					let initialFieldsLenght = null as any;

					return (
						<Form.List key={`tab-${i}`} name={name as string}>
							{(fields, {add, remove}) => (
								<>
									{fields.map(({key, name: fieldName}, index) => {
										if (initialFieldsLenght === null && !firstField.current)
											initialFieldsLenght = fields.length;

										const newLabel =
											formInitialValues &&
											formInitialValues[name as string] &&
											formInitialValues[name as string][index]?.label;

										return (
											<Col
												key={key}
												{...colProps}
												className={className ? className : ''}>
												<Row gutter={16} align="middle">
													{!!groupTitle && (
														<FormTitle
															label={groupTitle + ' ' + (index + 1)}
															colProps={{xs: 18}}
														/>
													)}
													{!disableDynamicActions &&
														minimalItem < fields.length && (
															<Col xs={6} style={{textAlign: 'right'}}>
																<Button
																	className="form-group-delete"
																	onClick={() => {
																		if (initialFieldsLenght > index)
																			initialFieldsLenght -= 1;
																		remove(fieldName);
																	}}
																	icon={<Trash size={18} weight="fill" />}
																/>
															</Col>
														)}
												</Row>
												<Row gutter={16} align="middle">
													{field.inputs?.map((input, i) => {
														const {
															visibility = ['USER', 'PARTNER', 'ADD', 'EDIT'],
															disabledAt
														} = input;

														if (visible && !visibility?.includes(visible))
															return null;
														return (
															<FormItem
																key={`formGroup-${i}`}
																name={[fieldName, input.name as string]}
																field={input}
																formErrors={formErrors}
																parentName={name}
																index={index}
																form={form}
																dynemicLabel={newLabel}
																disabled={
																	initialFieldsLenght > index &&
																	!!(
																		visible &&
																		disabledAt?.length &&
																		disabledAt.includes(visible)
																	)
																}
															/>
														);
													})}
												</Row>
												{!disableDivider && index !== fields.length - 1 && (
													<Row gutter={16} align="middle">
														<Col xs={24}>
															<Divider style={{margin: '0 0 15px'}} />
														</Col>
													</Row>
												)}
											</Col>
										);
									})}
									{!disableDynamicActions && (
										<Col xs={24}>
											<Form.Item>
												<Button
													type="dashed"
													onClick={() => {
														if (initialFieldsLenght === null)
															firstField.current = true;
														add();
													}}
													block
													icon={<PlusOutlined />}>
													{field.label}
												</Button>
											</Form.Item>
										</Col>
									)}
								</>
							)}
						</Form.List>
					);
				}
				if (field.type === 'group') {
					return (
						<div className="group" style={{width: '100%'}} key={`group-${field.name}`}>
							<Col {...colProps} className={className ? className : ''}>
								<Row gutter={16} align="middle">
									{field.inputs?.map((input, i) => {
										const {visibility = ['USER', 'PARTNER', 'ADD', 'EDIT'], disabledAt} =
											input;

										if (visible && !visibility?.includes(visible)) return null;
										return (
											<FormItem
												key={`group-${field.name}-${i}`}
												name={input.name as string}
												field={input}
												formErrors={formErrors}
												form={form}
												dynemicLabel={input.label}
												disabled={
													!!(
														visible &&
														disabledAt?.length &&
														disabledAt.includes(visible)
													)
												}
											/>
										);
									})}
								</Row>
							</Col>
						</div>
					);
				}

				return (
					<FormItem
						key={i}
						name={name}
						field={field}
						formErrors={formErrors}
						form={form}
						disabled={!!(isDisabled && isDisabled(form?.getFieldValue(name as string)))}
						selectedRowId={selectedRowId}
						visible={visible}
					/>
				);
			})}
		</Row>
	);
};

export default FormGroup;
