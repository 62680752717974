import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import DataExport from '../../../../Components/DataExport/DataExport';
import i18n from '../../../../utils/translate/i18';
import {usePagination} from '../../../../utils/usePagination/usePagination';
import DefaultWrapper from '../DefaultWrapper/DefaultWrapper';
import './PaymentHistory.less';
import filteritems from './filter_fields.form';
import PagePagination from '../../../../Components/Pagination/PagePagination';
import {Empty} from 'antd';
import dayjs from 'dayjs';

const PaymentHistory = () => {
	let {t} = useTranslation();

	const paymentHistory = useSelector(({paymentHistory}) => paymentHistory);
	const {currentItems, currentPage, goToPage} = usePagination(paymentHistory);

	const columns = [
		{dataIndex: 'paymentType', title: t('pages.account.paymentHistoryPage.filter.byPaymentMethod.title')},
		{dataIndex: 'date', title: t('pages.account.paymentHistoryPage.filter.byDate')},
		{dataIndex: 'amount', title: t('pages.account.paymentHistoryPage.ticketItem.ticketAbout.cost')},
		{dataIndex: 'type', title: t('pages.account.paymentHistoryPage.filter.type.title')}
	];
	const rows = currentItems?.map((historyPayment: any) => {
		return {
			// paymentType: historyPayment.routeNumber,
			// date: dayjs(historyPayment.transactionDate).format('DD-MM-YYYY').toString(),
			// amount: historyPayment.amount,
			// type: historyPayment.type
		};
	});
	return (
		<div className="payment-history">
			<div className="payment-history__container">
				<div className="payment-history__content">
					<div className="payment-history__rows">
						<div className="payment-history__top">
							<DataExport exportData={{rows, columns}} />
							<DefaultWrapper filteritems={filteritems} />
						</div>
						<div style={{padding: '50px 0px'}}>
							{/* {t('pages.account.paymentHistoryPage.noData')} */}
							<Empty />
						</div>
						{/* {currentItems.map((payment: any) => (
							<div
								className="payment-history__items__rows"
								key={payment.id}
								style={{marginTop: 20}}>
								<div className="payment-history__item">
									<div className="payment-history__item-title">
										{payment[`productName_${i18n.language}`]}
									</div>
									<div className="payment-history__subitem-date">
										{payment[`createdAt_${i18n.language}`]}
									</div>
									<div className="payment-history__subitem-moreinfo">
										<div className="subitem-moreinfo__rows">
											<div className="subitem-moreinfo-item">
												<div className="subitem-moreinfo-item__title">
													{t(
														'pages.account.paymentHistoryPage.ticketItem.ticketAbout.paymentMethod'
													)}
												</div>
												<div className="subitem-moreinfo-item__data">
													{payment[`paymentMethod_${i18n.language}`]}
												</div>
											</div>
											<div className="subitem-moreinfo-item">
												<div className="subitem-moreinfo-item__title">
													{t(
														'pages.account.paymentHistoryPage.ticketItem.ticketAbout.cost'
													)}
												</div>
												<div className="subitem-moreinfo-item__data">
													{payment.amount} {t('AMD')}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						))} */}
					</div>
					{/* <PagePagination
						currentPage={currentPage}
						totalItems={paymentHistory.length}
						onPageChange={goToPage}
					/> */}
				</div>
			</div>
		</div>
	);
};

export default PaymentHistory;
