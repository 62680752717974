import {Button, Form} from 'antd';
import {useForm} from 'antd/es/form/Form';
import {useTranslation} from 'react-i18next';
import {validateMessages} from '../../../../../Components/AuthModalForm';
import fields from '../fields.form';
import {useState} from 'react';
import FormGroup from '../../../../../Components/formItems/FormGroup';
import fastShift from './../../../../../assets/fast-shift-logo.png';
import masterCard from './../../../../../assets/icons/mastercard.svg';
import visa from './../../../../../assets/icons/visa.svg';
import {useMediaQuery} from 'react-responsive';
import {useSelector} from 'react-redux';

const OnlinePaymentTab = () => {
	let [form] = useForm();
	let {t} = useTranslation();
	let [formErrors, setFormErrors] = useState([]);
	const [showCaptcha, setShowCaptcha] = useState(true);
	const [captchaIsOk, setCaptchaIsOk] = useState(false);
	const auth = useSelector(({auth}) => auth.data);

	let paymentHandler = (type: any) => {
		switch (type) {
			case 'visa':
				form.validateFields()
					.then(() => {
						window.location.href = 'https://usa.visa.com/';
					})
					.catch(err => {
						const error = err.errorFields?.[0]?.name?.[0];

						if (error) {
							const errorField = document.querySelector(`[name="${error}"]`) as HTMLElement;
							errorField.scrollIntoView({
								behavior: 'smooth',
								block: 'center',
								inline: 'center'
							});
						}
					});
				break;
			case 'fastShift':
				form.validateFields()
					.then(() => {
						window.location.href = 'https://www.fastshift.am/en';
					})
					.catch(err => {
						const error = err.errorFields?.[0]?.name?.[0];

						if (error) {
							const errorField = document.querySelector(`[name="${error}"]`) as HTMLElement;
							errorField.scrollIntoView({
								behavior: 'smooth',
								block: 'center',
								inline: 'center'
							});
						}
					});
				break;
		}
	};

	const handleClick = (type: 'visa' | 'fastShift') => {
		form.submit();
		form.validateFields()

			.then(() => {
				setShowCaptcha(true);
				setTimeout(() => {
					let gg = new Promise(resolve => {
						resolve(true);
						paymentHandler(type);
					});
					gg.then(() => {
						setShowCaptcha(false);
						setCaptchaIsOk(false);
						form.resetFields();
					});
				}, 1000);
			})

			.catch(err => {
				const error = err.errorFields?.[0]?.name?.[0];

				if (error) {
					const errorField = document.querySelector(`[name="${error}"]`) as HTMLElement;
					errorField.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});
				}
			});
	};

	return (
		<div className="online-payment-tab">
			<div className="online-payment-tab__container">
				<div className="online-payment-tab__content">
					<div className="online-payment-tab__rows">
						<div className="online-payment-tab__row">
							<div className="my-balance__subheader">
								<p>{t('pages.account.myBalancePage.top.payOnline.paymentCount.header')}</p>
							</div>
							<Form
								// layout="inline"
								form={form}
								validateMessages={validateMessages(t)}
								onValuesChange={() => {}}
								onFinish={() => {}}>
								<FormGroup tabFields={fields(t)} formErrors={formErrors} form={form} />
								
							</Form>

							{t('pages.account.myBalancePage.top.payOnline.paymentCount.minMax')}
						</div>
						<div className="online-payment-tab__row">
							<div className="my-balance__subheader">
								<p>{t('pages.account.myBalancePage.top.payOnline.paymentMethod.header')}</p>
							</div>
							<div className="my-balance__payment-methods">
								<div className="my-balance__payment-method first-method">
									<div className="payment-method-imgs">
										<img src={visa} alt="" />
										<img src={masterCard} alt="" />
									</div>
									<Button
										onClick={() => {
											handleClick('visa');
										}}>
										{t(
											'pages.account.myBalancePage.top.payOnline.paymentMethod.visa.title'
										)}
									</Button>
								</div>
								<div className="my-balance__payment-method">
									<div className="payment-imgs">
										<img src={fastShift} alt="" />
									</div>

									<Button
										onClick={() => {
											handleClick('fastShift');
										}}>
										{t(
											'pages.account.myBalancePage.top.payOnline.paymentMethod.fastShift.title'
										)}
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OnlinePaymentTab;
