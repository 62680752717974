import {useSelector} from 'react-redux';
import {Navigate, useLocation} from 'react-router-dom';

export function PrivateRoute({
	isAuthenticated,
	isPrivate,
	children,
	role
}: {
	isAuthenticated: boolean;
	isPrivate?: boolean | undefined;
	children: React.ReactNode;
	role: number[] | undefined;
}) {
	let location = useLocation();
	const auth = useSelector(({auth}) => auth.data);
	if ((!isAuthenticated && isPrivate) || (role && !role.includes(auth.roleId))) {
		return <Navigate to="/" state={{from: location}} replace />;
	}

	return children;
}
