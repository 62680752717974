import {useTranslation} from 'react-i18next';
import './RouteItemTransport.less';
import i18n from '../../../../../../../utils/translate/i18';
import moment from 'moment';
import { Divider } from 'antd';

function RouteItemTransport({options}: any) {
	let {t} = useTranslation();
	const validatorSerialNumber = options.validator?.serialNumber;

	return (
		<div className="route-item-transport__item">
			<div className="route-item-transport__row">
				<div className="route-item-transport__top">
					<div className="route-item-transport__number">
						<p>{options.yellowLicensePlate}</p>
					</div>
					<div className="route-item-transport__manufacturer">
						<p>{options.category && options.category[i18n.language]}</p>
					</div>
				</div>
				<Divider style={{background: '#009E83'}} />
				<div className="route-item-transport__info">
					<div className="route-item-transport__info-row">
						{/* more info */}
						<div className="route-item-transport__info-item">
							<div className="route-item-transport__info-title">
								{t('pages.account.myBalancePage.top.bankTransfer.table.city')}
							</div>
							<div className="route-item-transport__info-main">
								{options.route.cityId === 1
									? t('header.menu.news.vanadzor')
									: t('header.menu.news.gyumri')}
							</div>
						</div>
						<div className="route-item-transport__info-item">
							<div className="route-item-transport__info-title">
								{t('pages.account.partner.routes.popups.buses.type')}
							</div>
							<div className="route-item-transport__info-main">
								{options.type && options.type[i18n.language]}
							</div>
						</div>
						<div className="route-item-transport__info-item">
							<div className="route-item-transport__info-title">
								{t('pages.account.partner.routes.popups.buses.numberPlate')}
							</div>
							<div className="route-item-transport__info-main">
								<span>{options.licensePlate}</span>
							</div>
						</div>
						<div className="route-item-transport__info-item">
							<div className="route-item-transport__info-title">
								{t('pages.account.partner.routes.popups.buses.yellowNumberPlate')}
							</div>
							<div className="route-item-transport__info-main">
								<span>{options.yellowLicensePlate}</span>
							</div>
						</div>
						<div className="route-item-transport__info-item">
							<div className="route-item-transport__info-title">
								{t('pages.account.partner.routes.popups.buses.yellowNumberPlateData')}
							</div>
							<div className="route-item-transport__info-main">
								<span>
									{options.yellowLicensePlateReceiptDate !== null
										? moment(options.yellowLicensePlateReceiptDate).format('DD-MM-YYYY')
										: '-'}
								</span>
							</div>
						</div>
						<div className="route-item-transport__info-item">
							<div className="route-item-transport__info-title">
								{t('pages.account.partner.routes.popups.buses.yellowNumberPlateData1')}
							</div>
							<div className="route-item-transport__info-main">
								<span>
									{options.yellowLicensePlateDeadline !== null
										? moment(options.yellowLicensePlateDeadline).format('DD-MM-YYYY')
										: '-'}
								</span>
							</div>
						</div>
						<div className="route-item-transport__info-item">
							<div className="route-item-transport__info-title">
								{t('pages.account.partner.routes.popups.buses.productionDate')}
							</div>
							<div className="route-item-transport__info-main">
								<span>
									{options.releaseDate !== null
										? moment(options.releaseDate).format('DD-MM-YYYY')
										: '-'}
								</span>
							</div>
						</div>
						<div className="route-item-transport__info-item">
							<div className="route-item-transport__info-title">
								{t('pages.account.partner.routes.popups.buses.numberOfSeats')}
							</div>
							<div className="route-item-transport__info-main">
								<span>{options.numberOfSeats !== null ? options.numberOfSeats : '-'}</span>

								{t('pages.account.partner.routes.popups.buses.info.seats')}
							</div>
						</div>
						<div className="route-item-transport__info-item">
							<div className="route-item-transport__info-title">
								{t('pages.account.partner.routes.popups.buses.standingPassengersCount')}
							</div>
							<div className="route-item-transport__info-main">
								<span>
									{' '}
									{options.numberOfStandingPassengers !== null
										? options.numberOfStandingPassengers
										: '-'}
								</span>
								{t('pages.account.partner.routes.popups.buses.info.passengers')}
							</div>
						</div>
						<div className="route-item-transport__info-item">
							<div className="route-item-transport__info-title">
								{t('pages.account.partner.routes.popups.buses.fuelType')}
							</div>
							<div className="route-item-transport__info-main">
								<span>{options.fuelType !== null ? options.fuelType : '-'}</span>
							</div>
						</div>
						<div className="route-item-transport__info-item">
							<div className="route-item-transport__info-title">
								{t('pages.account.partner.transactions.terminal')}
							</div>
							<div className="route-item-transport__info-main">
								<span>{validatorSerialNumber ? validatorSerialNumber : '-'}</span>
							</div>
						</div>
						<div className="route-item-transport__info-item">
							<div className="route-item-transport__info-title">
								{t('pages.account.partner.routes.routeItem.marchName')}
							</div>
							<div className="route-item-transport__info-main">
								{options.route.number !== null ? options.route.number : '-'}
							</div>
						</div>

						<div className="route-item-transport__info-item">
							<div className="route-item-transport__info-title">
								{t(
									'pages.account.ticketHoldersPage.ticketHolder.description.main.status.title'
								)}
							</div>
							<div className="route-item-transport__info-main">
								{options.status !== null
									? options.status && options.status[i18n.language]
									: '-'}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default RouteItemTransport;
