import {passwordRules} from '../../../../utils/rules/validation';
import {ModalFormFieldTypesFunc} from '../../../../utils/types';

const passwordFields: ModalFormFieldTypesFunc = t => [
	{
		name: 'currentPassword',
		label: t('actions.entercurrentpassword'),
		type: 'password',
		rules: [
			passwordRules(t),
			{required: true, message: t('messages.passwordMinLength')},
			{min: 8, message: t('messages.passwordMinLength')}
		],
		colProps: {
			xs: 24
		}
	},
	{
		name: 'password',
		label: t('actions.currentnewPassword'),
		type: 'password',
		dependencies: ['currentPassword'],
		rules: [
			passwordRules(t),
			{required: true, message: t('messages.requiredField')},
			{min: 8, message: t('messages.passwordMinLength')}
		],
		colProps: {
			xs: 24
		}
	},
	{
		name: 'rePassword',

		label: t('actions.repetnewpassword'),
		type: 'password',
		dependencies: ['password'],
		rules: [
			passwordRules(t),
			{required: true, message: t('messages.requiredField')},
			{min: 8, message: t('messages.passwordMinLength')},
			({getFieldValue}: {getFieldValue: (name: any) => any}) => ({
				validator(_: any, value: any) {
					if (value !== getFieldValue('password')) {
						return Promise.reject(new Error(t('messages.error.passwordNotMatch')));
					}
					return Promise.resolve();
				}
			})
		],
		colProps: {
			xs: 24
		}
	}
];

export default passwordFields;
