import React, {useEffect, useState} from 'react';
import {Collapse} from 'antd';
import {Bell, CaretDown, CaretUp} from 'phosphor-react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {INotification, TranslationFuncType} from '../../../../utils/types';
import DefaultWrapper from '../DefaultWrapper/DefaultWrapper';
import notificationForm from './NotificationForm';
import './Notifications.less';
import i18n from '../../../../utils/translate/i18';
import PagePagination from '../../../../Components/Pagination/PagePagination';
import {useLocation} from 'react-router-dom';

const CustomArrow = ({isExpanded}: any) => (
	<div>
		{isExpanded ? (
			<CaretUp size={22} weight="thin" color="#009e83" style={{float: 'right'}} />
		) : (
			<CaretDown size={22} weight="thin" color="#009e83" style={{float: 'right'}} />
		)}
	</div>
);

function mutateData(notifications: INotification[], t: TranslationFuncType, activeKeys: string[]) {
	return notifications.map((notification: any) => ({
		label: (
			<div className="notificatiopns-item-label" key={notification.id}>
				{notification.isRead ? (
					<Bell
						style={{position: 'absolute', right: 15, top: 10}}
						size={14}
						color="#009e83"
						weight="fill"
					/>
				) : null}
				<div
					className="notificatiopns-item-label-title"
					style={{color: activeKeys.includes(notification.id) ? '#009E83' : '#121212'}}>
					{t(notification.title)}
				</div>
			</div>
		),
		children: (
			<div className="notificatiopns-item-content">{notification[`description_${i18n.language}`]}</div>
		),
		key: notification.id
	}));
}

const Notifications = () => {
	const {t} = useTranslation();
	const [readNotifications, setReadNotifications] = useState<string[]>([]);
	const notifications = useSelector(({notifications}) => notifications);
	const [activeKeys, setActiveKeys] = useState<string[]>([]);
	const [currentPage, setCurrentPage] = useState(1);
	let location = useLocation();
	const items = mutateData(notifications, t, activeKeys);

	const handleCollapseChange = (keys: any) => {
		setActiveKeys(keys);
		const newNotification = keys.filter((key: any) => !readNotifications.includes(key));
		setReadNotifications(prev => [...prev, ...newNotification]);
	};

	const handlePageChange = (page: number) => {
		setCurrentPage(page);
	};

	useEffect(() => {
		return () => {
			setActiveKeys([]);
		};
	}, [location.pathname]);
	return (
		<div className="notifications-container">
			<div className="notification-form">
				<DefaultWrapper filteritems={notificationForm} />
			</div>
			<div className="notifications-collapse">
				<Collapse
					accordion
					bordered={false}
					defaultActiveKey={activeKeys}
					activeKey={activeKeys}
					onChange={handleCollapseChange}
					expandIcon={({isActive}) => <CustomArrow isExpanded={isActive} />}
					items={items}
				/>
			</div>
			<PagePagination
				currentPage={currentPage}
				totalItems={notifications.length}
				onPageChange={handlePageChange}
			/>
		</div>
	);
};

export default Notifications;
