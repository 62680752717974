import API from '../api';
import axiosClient from '../utils/axiosClient';

const getDrivers = async (data: any) => {
	const res = await axiosClient.get(API.DRIVERS.drivers, {
		params: {...data.filter, page: data.page, take: 4}
	});
	return res?.data;
};

const DRIVERS = {
	getDrivers
};

export default DRIVERS;
