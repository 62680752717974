import {ModalFormFieldTypesFunc} from '../../../../utils/types';

const notificationForm: ModalFormFieldTypesFunc = t => [
	{
		name: 'dateRange',
		isRequired: false,
		label: t('pages.account.notificationss.filter.title'),
		type: 'dateRange',

		colProps: {
			lg: 12,
			xs: 24
		}
	}
];
export default notificationForm;
