import {ModalFormFieldTypesFunc} from '../../../../utils/types';

const fields: ModalFormFieldTypesFunc = t => [
	{
		name: 'byPaymentMethod',
		label: t('pages.account.paymentHistoryPage.filter.byPaymentMethod.title'),
		type: 'select',
		placeholder: t('pages.account.paymentHistoryPage.filter.byPaymentMethod.placeholder'),
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		},
		selectProps: {
			options: [
				{
					label: t('pages.account.paymentHistoryPage.filter.byPaymentMethod.options.byTerminal'),
					value: 'byterminal'
				},
				{
					label: t('pages.account.paymentHistoryPage.filter.byPaymentMethod.options.byCreditCard'),
					value: 'creditcard'
				},
				{
					label: t('pages.account.paymentHistoryPage.filter.byPaymentMethod.options.byBalance'),
					value: 'balance'
				}
			]
		}
	},
	{
		name: 'byPurchaseDate',
		label: t('pages.account.paymentHistoryPage.filter.byDate'),
		type: 'dateRange',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		}
	},
	
	{
		name: 'type',
		label: t('pages.account.paymentHistoryPage.filter.type.title'),
		type: 'select',
		placeholder: t('pages.account.paymentHistoryPage.filter.type.placeholder'),
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		},
		selectProps: {
			options: [
				{
					label: t('pages.account.paymentHistoryPage.filter.type.options.ticketPurchase'),
					value: '100'
				},
				{
					label: t('pages.account.paymentHistoryPage.filter.type.options.chargeBalance'),
					value: '200'
				}
			]
		}
	}
];
export default fields;
