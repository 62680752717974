import {Button} from 'antd';
import csvExport from '../../utils/csvExport/exportCSV';
import excelExport from '../../utils/excelExport/excelExport';
import csvicon from './../../assets/icons/exportcsv.svg';
import xlsxicon from './../../assets/icons/exportxlsx.svg';
import './DataExport.less';

const DataExport = ({exportData}: any) => {
	let handleExportData = (type: any) => {
		switch (type) {
			case 'xlsx':
				excelExport(exportData);
				break;
			case 'csv':
				csvExport(exportData);
				break;
		}
	};
	return (
		<div className="my-tickets__export">
			<div className="my-tickets__export-buttons">
				<Button onClick={() => handleExportData('xlsx')}>
					<img src={xlsxicon} alt="" />
				</Button>
				<Button>
					<img src={csvicon} alt="" onClick={() => handleExportData('csv')} />
				</Button>
			</div>
		</div>
	);
};

export default DataExport;
