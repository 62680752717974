import {ModalFormFieldTypesFunc} from '../../../../../utils/types';

const providedServicesform: ModalFormFieldTypesFunc = t => [
	{
		name: 'cityId',
		label: t('pages.account.partner.transfersHistory.bystatus'),
		type: 'select',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		},

		dictionaryApiPath: 'cities'
	},
	{
		name: 'date',
		label: t('pages.account.partner.transfersHistory.filter.date'),
		type: 'dateRange',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		}
	},
	{
		name: 'money',
		label: t('pages.account.partner.transfersHistory.filter.money'),
		type: 'select',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		},

		selectProps: {
			options: [
				{
					label: t('pages.account.partner.transfersHistory.filter.upto'),
					value: 'gyumry'
				},
				{
					label: t('pages.account.partner.transfersHistory.filter.upto2'),
					value: 'sayatnova'
				},
				{
					label: t('pages.account.partner.transfersHistory.filter.upto1'),
					value: 'tigtanmec'
				}
			]
		}
	},
	{
		name: 'notes',
		label: t('pages.account.partner.transfersHistory.filter.notes'),
		type: 'text',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		},
		inputProps: {
			style: {height: '50px'}
		}
	},
	{
		name: 'transferDate',
		label: t('pages.account.partner.transfersHistory.filter.transferDate'),
		type: 'dateRange',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		}
	}
];
export default providedServicesform;
