import {useTranslation} from 'react-i18next';
import {IPassengerTickets} from '../../../../../utils/types';
import MainTicket from '../../../../Tickets/MainTicket/MainTicket';
import './OwnedTicket.less';
import {Button, Modal, QRCode} from 'antd';
import {useRef, useState} from 'react';
import {useMediaQuery} from 'react-responsive';
// @ts-ignore
import {saveAs} from 'file-saver';

const OwnedTicket = ({ticket}: any) => {
	let {t} = useTranslation();
	let [open, setOpen] = useState(false);
	const monthUse = ticket.carrierTypeID === 1;
	const isMobile = useMediaQuery({maxWidth: 480});
	const qrRef = useRef();

	const saveQRCode = () => {
		// @ts-ignore
		const qrCanvas = qrRef.current.querySelector('canvas');
		if (!qrCanvas) {
			console.error('QR code canvas not found!');
			return;
		}
		const qrSize = qrCanvas.width;
		const borderWidth = 20; // Set the desired border width
		const newCanvasSize = qrSize + 2 * borderWidth;

		// Create a new canvas with larger dimensions
		const canvasWithBorders = document.createElement('canvas');
		const context = canvasWithBorders.getContext('2d');

		// Set the new canvas dimensions
		canvasWithBorders.width = newCanvasSize;
		canvasWithBorders.height = newCanvasSize;

		// Fill the new canvas with a white background
		// @ts-ignore
		context.fillStyle = '#ffffff'; // White background
		// @ts-ignore
		context.fillRect(0, 0, newCanvasSize, newCanvasSize);

		// Draw the original QR code onto the new canvas with white borders
		// @ts-ignore
		context.drawImage(qrCanvas, borderWidth, borderWidth);

		canvasWithBorders.toBlob((blob: any) => {
			if (blob) {
				saveAs(blob, 'qr_code.jpg');
			} else {
				console.error('Failed to convert QR code to blob.');
			}
		}, 'image/jpg');
	};
	return (
		<div className="owned-ticket">
			<div className="owned-ticket__rows">
				<div className="owned-ticket__header">
					{/* @ts-ignore */}
					<p>{ticket.nameHy}</p>
				</div>
				<div className="owned-ticket__main">
					<MainTicket showTicketIco={true} oneUse={!monthUse} options={ticket} />
					<div className="owned-tikcet__qrcode">
						<Modal
							open={open}
							onCancel={() => setOpen(false)}
							centered
							footer={null}
							width={'auto'}
							closeIcon={false}>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									flexDirection: 'column',
									gap: 10
								}}>
								<QRCode value={ticket.qrcode} size={isMobile ? 250 : 160} />
								<Button type="primary" onClick={saveQRCode}>
									{t('actions.saveQRCode')}
								</Button>
							</div>
						</Modal>
						<Button
							className="qr_modal_button"
							style={{outline: 'none'}}
							ghost
							onClick={() => setOpen(true)}>
							{/*  @ts-ignore */}
							<div ref={qrRef}>
								<QRCode value={ticket.qrcode} />
							</div>
						</Button>
					</div>
				</div>
				<div className="owned-ticket-description">
					<div className="owned-ticket-description__header">
						<p>{t('pages.account.myTickets.description')}</p>
					</div>
					<div className="owned-ticket-description__main">
						{/* @ts-ignore */}
						{ticket.descriptionHy}
					</div>
				</div>
			</div>
		</div>
	);
};

export default OwnedTicket;
