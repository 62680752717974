import {Radio} from 'antd';

const RadioField = ({radioFields, ...props}: any) => {
	return (
		<Radio.Group className="rating-button" {...props}>
			{radioFields.map((val: any) => (
				<Radio key={val.value} value={val.value}>
					{val.label}
				</Radio>
			))}
		</Radio.Group>
	);
};

export default RadioField;
