import {useState} from 'react';
import {Upload, Button, message} from 'antd';
import {UploadOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';

const FileUploadField = (props: any) => {
	const {t} = useTranslation();
	const {editableFile, ...rest} = props;
	const [fileList, setFileList] = useState<any>([]);
	const [uploadError, setUploadError] = useState<string | null>(null);
	const types = [
		'image/jpeg',
		'image/jpg',
		'application/pdf',
		'image/png',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		'application/msword'
	];
	const uploadProps = {
		onRemove: (file: any) => {
			setFileList([]);
		},
		beforeUpload: (file: any) => {
			if (!types.includes(file.type)) {
				setUploadError(t('messages.error.fileUploadError', {fileName: file.name}));
				return false;
			}
			if (file.size / 1024 / 1024 >= 2) {
				setUploadError(t('actions.image2MB'));
				return false;
			}
			if (fileList.length > 0) {
				setUploadError(t('messages.error.maxUploadCount', {maxItemCount: 1}));
				return false;
			}

			setFileList([file]);
			setUploadError(null);

			return false;
		},
		fileList
	};

	return (
		<div style={{display: 'flex', flexDirection: 'column'}}>
			{(editableFile || typeof rest.value !== 'string') && (
				<>
					<Upload {...rest} {...uploadProps} maxCount={1}>
						<Button
							icon={<UploadOutlined />}
							type="dashed"
							disabled={fileList.length >= 1}
							className={props.className || undefined}>
							{t('actions.uploadFile')}
						</Button>
					</Upload>
					<div className="upload-error" style={{color: 'red'}}>
						{uploadError}
					</div>
				</>
			)}
			{typeof rest.value === 'string' && (
				<a href={process.env.REACT_APP_API_URL + '/' + rest.value} target="_blank" rel="noreferrer">
					{rest.value.substr(rest.value.lastIndexOf('/') + 1)}
				</a>
			)}
		</div>
	);
};

export default FileUploadField;
