// @ts-ignore
import {Button} from 'antd';
import gif404 from './../../assets/404/404.gif';
import './NotFound.less';
import {useTranslation} from 'react-i18next';

function NotFound() {
	let {t} = useTranslation();
	return (
		<div className="not-found">
			<div className="not-found__container">
				<div className="not-found__content">
					<div className="not-found__rows">
						<div className="not-found__row">
							<div className="not-found__gif">
								<img src={gif404} alt="" />
							</div>
						</div>
						<div className="not-found__row bottom">
							<div className="not-found__header">
								<p>{t('pages.notFound.title')} </p>
							</div>
							<div className="not-found__subtitle">
								<p>{t('pages.notFound.subTitle')}</p>
							</div>
							<Button href="/">{t('pages.notFound.button')}</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default NotFound;
