import {FC} from 'react';

type BusNumber = {
	number: any;
};

const BusNumber: FC<BusNumber> = ({number}) => {
	return (
		<div className="transports-item">
			{number}
		</div>
	);
};

export default BusNumber;
