import {useTranslation} from 'react-i18next';
import DataExport from '../../../../../Components/DataExport/DataExport';
import DefaultWrapper from '../../DefaultWrapper/DefaultWrapper';
import providedServicesform from './TransfersHistoryform';
import './TransfersHistory.less';
import {useDispatch, useSelector} from 'react-redux';
import PagePagination from '../../../../../Components/Pagination/PagePagination';
import {useEffect, useState} from 'react';
import TransfersHistoryData from './TransfersHistoryData';
import {AppDispatch} from '../../../../../store';
import {usePagination} from '../../../../../utils/usePagination/usePagination';
import {getPartnerTransfers} from '../../../../../store/partner.transferHistory.slice';
import {Empty} from 'antd';
const TransfersHistory = () => {
	const dispatch = useDispatch<AppDispatch>();

	const auth = useSelector((state: any) => state.auth.data);
	const transfersHistory = useSelector((state: any) => state.partnerTransfers?.data || {});
	const {currentItemsData, currentPage, goToPage} = usePagination(
		transfersHistory?.items,
		transfersHistory?.pageSize
	);
	const [info, setInfo] = useState<any>(null);

	useEffect(() => {
		dispatch(getPartnerTransfers({page: currentPage, filter: info}));
	}, [auth.id, currentPage, info, dispatch]);

	return (
		<div className="transfers-history-box-all">
			<div className="transfers-history-dropdown-div">
				<div className="transfers-history-data-export">
					<DataExport />
				</div>
				<div className="transfers-history-default-wrapper">
					<DefaultWrapper
						filteritems={providedServicesform}
						defaultFilters={info}
						applyFilters={(data: any) => {
							setInfo(data);
						}}
					/>
				</div>
			</div>

			{currentItemsData && currentItemsData.length > 0 ? (
				<div>
					<div className="partner-drivers__row">
						<div className="route-drivers">
							<div className="route-drivers-container">
								<div className="route-drivers-content">
									<div className="route-drivers-row">
										{currentItemsData?.map((item: any) => (
											<TransfersHistoryData key={item.id} options={item} />
										))}
									</div>
								</div>
							</div>
						</div>
					</div>

					<div style={{marginTop: 50}}>
						<PagePagination
							pageSize={transfersHistory.pageSize}
							currentPage={transfersHistory.currentPage}
							totalItems={transfersHistory.totalCount}
							onPageChange={goToPage}
						/>
					</div>
				</div>
			) : (
				<div style={{padding: '50px 0px'}}>
					<Empty />
				</div>
			)}
		</div>
	);
};

export default TransfersHistory;
