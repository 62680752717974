import {getKeyByLang} from '../../../../../utils/heplerFuncs/functions';
import {ModalFormFieldTypesFunc} from '../../../../../utils/types';

const fields: ModalFormFieldTypesFunc = t => [
	{
		name: getKeyByLang('fullName'),
		label: t('pages.account.partner.drivers.titles'),
		type: 'text',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		},
		inputProps: {
			style: {height: '50px'}
		}
	},
	{
		name: 'routeNumber',
		isRequired: false,
		label: t('pages.account.historyMarches.byroute'),
		type: 'select',
		allowClear: true,
		colProps: {
			lg: 24,
			xs: 24
		},
		fetchOnDepValue: false,
		customApiFetch: {
			path: 'api/v1/routes/public',
			labelKey: val => val?.number,
			valueKey: 'number'
		}
	},

	{
		name: 'transportYellowLicensePlate',
		label: t('pages.account.partner.buses.titles'),
		type: 'select',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		},
		fetchOnDepValue: false,
		customApiFetch: {
			path: 'api/v1/partners/transports',
			labelKey: 'yellowLicensePlate',
			valueKey: 'yellowLicensePlate'
		}
	},
	{
		name: 'driversLicenseNumber',
		label: t('pages.account.partner.routes.popups.drivers.driversLicenseNumber'),
		type: 'text',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		},
		inputProps: {
			style: {height: '50px'}
		}
	},
	{
		name: 'workContract',
		label: t('pages.account.partner.routes.popups.drivers.employmentContractNumber'),
		type: 'text',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		},
		inputProps: {
			style: {height: '50px'} // Set the height to 20px
		}
	},
	{
		name: 'expirationOfDriversLicense',
		label: t('pages.account.partner.drivers.filter.driversLicenseEndDate'),
		type: 'dateRange',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		}
	},
	{
		name: 'issuanceOfDriversLicense',
		label: t('pages.account.partner.drivers.filter.driversLicenseStartDate'),
		type: 'dateRange',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		}
	},
	{
		name: 'statusId',
		label: t('pages.account.partner.transactions.transferstatus'),
		type: 'select',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		},
		inputProps: {
			style: {height: '50px', border: 'none'}
		},
		dictionaryApiPath: 'driver-statuses'
	}
];
export default fields;
