import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Modal, Empty} from 'antd';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import DataExport from '../../../../Components/DataExport/DataExport';
import DefaultWrapper from '../DefaultWrapper/DefaultWrapper';
import PagePagination from '../../../../Components/Pagination/PagePagination';
import {getUserTransactions} from '../../../../store/account.historyMarches.slice';
import {usePagination} from '../../../../utils/usePagination/usePagination';
import i18n from '../../../../utils/translate/i18';
import './HistoryMarches.less';
import historyMarchesform from './historyMarchesform';
import {AppDispatch} from '../../../../store';
import dayjs from 'dayjs';

const HistoryMarches = () => {
	const {t} = useTranslation();
	const dispatch = useDispatch<AppDispatch>();

	const auth = useSelector((state: any) => state.auth.data);
	const historyMarches = useSelector(({historyMarches}) => historyMarches.data);

	const [info, setInfo] = useState<any>(null);

	const {currentPage, goToPage, currentItemsData} = usePagination(
		historyMarches?.items,
		historyMarches?.pageSize
	);
	useEffect(() => {
		if (auth.id) {
			dispatch(getUserTransactions({ page: currentPage, filter: info}));
		}
	}, [auth.id, currentPage, info]);

	const columns = [
		{dataIndex: 'city', title: t('fields.city')},
		{dataIndex: 'routeNumber', title: t('pages.account.historyMarches.marchestitle')},
		{dataIndex: 'ticketType', title: t('pages.account.historyMarches.product')},
		{dataIndex: 'holderType', title: t('pages.account.historyMarches.carriertitle')},
		{dataIndex: 'amount', title: t('pages.account.partner.providedServices.filter.money')},
		{dataIndex: 'transactionDate', title: t('pages.account.partner.transfersHistory.filter.date')}
	];
	const rows = currentItemsData?.map((historyItem: any) => {
		return {
			city: historyItem?.city ? historyItem?.city[i18n.language] : ' ',
			routeNumber: historyItem?.routeNumber,
			ticketType: historyItem?.ticketType ? historyItem?.ticketType[i18n.language] : ' ',
			holderType: historyItem?.holderType ? historyItem?.holderType[i18n.language] : ' ',
			amount: historyItem?.amount,
			transactionDate: dayjs(historyItem?.transactionDate).format('DD-MM-YYYY').toString()
		};
	});
	return (
		<div className="history-marches">
			<div className="box-data-export-default-wrapper">
				<div className="history-marches__top">
					<DataExport exportData={{rows, columns}} />
					<DefaultWrapper
						filteritems={historyMarchesform}
						defaultFilters={info}
						applyFilters={(data: any) => {
							setInfo(data);
							goToPage(1);
						}}
					/>
				</div>
			</div>
			<div className="all-data-box-file">
				{currentItemsData && currentItemsData.length > 0 ? (
					currentItemsData.map((el: any) => (
						<div key={el.id} className="all-activ-data">
							<p className="all-activ-data-name">{el.city && el.city[i18n.language]}</p>
							<p className="data">
								<span>{moment(el.transactionDate).format('DD')} </span>
								<span>{t(`months.${moment(el.transactionDate).month()}`)} </span>
								<span>{moment(el.transactionDate).format('YYYY')} </span>
								<span>{moment(el.transactionDate).format('HH:mm')} </span>
							</p>
							<div className="title-box" style={{display: 'flex'}}>
								<p className="title">{t('pages.account.historyMarches.marchestitle')}</p>
								<p className="text">{el.routeNumber}</p>
							</div>
							<div className="title-box">
								<p className="title">{t('pages.account.historyMarches.product')}</p>
								<p className="text">{el.ticketType && el.ticketType[i18n.language]}</p>
							</div>
							<div className="title-box">
								<p className="title">{t('pages.account.historyMarches.carriertitle')}</p>
								<p className="text">{el.holderType && el.holderType[i18n.language]}</p>
							</div>
							<div className="title-box">
								<p className="title">{t('pages.account.partner.transactions.amount')}</p>
								<p className="text">
									{el.amount.slice(0, -3)} {t('AMD')}
								</p>
							</div>
						</div>
					))
				) : (
					<div style={{padding: '50px 0px'}}>
						<Empty />
					</div>
				)}
			</div>
			{currentItemsData && currentItemsData.length > 0 && (
				<PagePagination
					pageSize={historyMarches.pageSize}
					currentPage={historyMarches.currentPage}
					totalItems={historyMarches.totalCount}
					onPageChange={goToPage}
				/>
			)}
		</div>
	);
};

export default HistoryMarches;
