import parsePhoneNumberFromString from 'libphonenumber-js';
import {TranslationFuncType} from '../types';

export const passwordRules = (t: TranslationFuncType) => ({
	pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[ !"#$%&'()*+,./:;<=>?@[\]^_`{|}~£-]).{8,}$/,
	message: t('messages.error.passwordCriteria')
});

export const latinRulesWithNumber = (t: TranslationFuncType) => ({
	pattern: new RegExp("^[A-Za-z-' 0-9 №#.:()*&^%$@,! ]{1,999}$"),
	message: t('messages.error.latinInputOnly')
});

export const armenianPhoneNumberRule = (t: TranslationFuncType) => ({
	pattern: new RegExp('^374\\d{2}\\d{6}$'),
	message: t('messages.error.armenianPhoneNumber')
});

export const cardNumberRules = (t: TranslationFuncType) => ({
	validator: (_: any, value: string) =>
		!value || /^[0-9]{4}-[0-9]{4}-[0-9]{4}-[0-9]{4}$/.test(value)
			? Promise.resolve()
			: Promise.reject(new Error(t('messages.error.invalidCardNumber')))
});

export const numberRules = (t: TranslationFuncType) => ({
	validator: (_: any, value: number) =>
		!value || (!isNaN(value) && value >= 0 && String(value)?.length < 13)
			? Promise.resolve()
			: String(value)?.length > 12
			? Promise.reject(new Error(t('messages.error.max12Characters')))
			: Promise.reject(new Error(t('messages.error.numberInputOnly')))
});
export const numberRuless = (t: TranslationFuncType) => ({
	validator: (_: any, value: number) =>
		!value || (!isNaN(value) && value >= 0 && String(value)?.length < 7)
			? Promise.resolve()
			: String(value)?.length > 6
			? Promise.reject(new Error(t('messages.error.max6Characters')))
			: Promise.reject(new Error(t('messages.error.numberInputOnly')))
});
export const numberRulesAmount = (t: TranslationFuncType, amount: number) => ({
	validator: (_: any, value: number) =>
		!value || (!isNaN(value) && value >= 0 && value <= amount && String(value)?.length < 13)
			? Promise.resolve()
			: value > amount
			? Promise.reject(new Error(t('messages.error.maxAmountIs', {amount: amount})))
			: String(value)?.length > 12
			? Promise.reject(new Error(t('max12Characters')))
			: Promise.reject(new Error(t('numberInputOnly')))
});

export const numberRulesAmountLittle = (t: TranslationFuncType, amount: number) => ({
	validator: (_: any, value: number) =>
		!value || (!isNaN(value) && value >= 0 && value >= amount && String(value)?.length < 13)
			? Promise.resolve()
			: value < amount
			? Promise.reject(new Error(t('numberWithAmountLittle', {amount: amount})))
			: String(value)?.length > 12
			? Promise.reject(new Error(t('max12Characters')))
			: Promise.reject(new Error(t('numberInputOnly')))
});

export const latinRules = (t: TranslationFuncType) => ({
	pattern: new RegExp("^[A-Za-z-' / ]{1,100}$"),
	message: t('latinInputOnly')
});

export const maxLengthRules = (maxLength: number, t: TranslationFuncType) => ({
	max: maxLength,
	message: t('_100_simbols', {amount: maxLength})
});

export const minChars = (t: TranslationFuncType, minChars: number) => ({
	min: minChars,
	message: t('messages.error.atLeastChars', {minChars})
});

export const maxChar = (t: TranslationFuncType, max: number) => ({
	max: max,
	message: t('messages.error.maxLength', {max})
});

export const russianRules = (t: TranslationFuncType) => ({
	pattern: new RegExp("^[А-Яа-я-ё,Ё -' /]{1,999}$"),
	message: t('rusInputOnly')
});

export const russianRulesWithNumber = (t: TranslationFuncType) => ({
	pattern: new RegExp("^[А-Яа-я-ё Ё 0-9 №#.:()*&^%$@!/, -']{1,999}$"),
	message: t('rusInputOnly')
});

export const armenianRules = (t: TranslationFuncType) => ({
	pattern: new RegExp("^[Ա-Ֆա-ֆ-և -'` /]{1,999}$"),
	message: t('armInputOnly')
});

export const armenianRulesWithNumber = (t: TranslationFuncType) => ({
	pattern: new RegExp("^[Ա-Ֆա-ֆ-և 0-9 №#.:`()*&^%$@,/! -']{1,999}$"),
	message: t('armInputOnly')
});

export const linkRule = (t: TranslationFuncType) => ({
	pattern: new RegExp(
		/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/
	),
	message: t('errorMessage_incorrectValue')
});

export const alphaNumeric = (t: TranslationFuncType) => ({
	pattern: new RegExp('^[A-Za-z0-9]{1,999}$'),
	message: t('onlyLetterOrNumber')
});

export const atLeastOneLetter = (t: TranslationFuncType) => ({
	pattern: new RegExp('\\p{L}+', 'u'),
	message: t('errorMessage_incorrectValue')
});

export const internationalPhoneNumberRule = (t: TranslationFuncType) => ({
	validator: (_: any, value: string) => {
		// If the value is empty, resolve the promise without any errors.
		if (!value) {
			return Promise.resolve();
		}

		// If the value is not empty, perform phone number validation.
		const phoneNumber = parsePhoneNumberFromString(value);

		if (phoneNumber && phoneNumber.isValid()) {
			return Promise.resolve();
		} else {
			return Promise.reject(new Error(t('messages.error.phoneNumberError')));
		}
	}
});

export const maxCharLimit = (maxLength: number, t: any) => ({
	validator: (_: any, value: any) =>
		!value || value.length <= maxLength
			? Promise.resolve()
			: Promise.reject(new Error(t('messages.error.max150Characters')))
});

export const numberOnlyRule = (t: TranslationFuncType) => ({
	pattern: new RegExp('^\\d+$'),
	message: t('messages.error.numberInputOnly')
});
