import API from '../api';
import axiosClient from '../utils/axiosClient';

const getPartnerRoutes = async (data: any) => {
	const res = await axiosClient.get(API.PARTNERROUTES.partnerRoutes, {
		params: {...data.filter, page: data.page, take: 4}
	});
	return res?.data;
};

const PARTNERROUTES = {
	getPartnerRoutes
};

export default PARTNERROUTES;
