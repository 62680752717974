import React, {useEffect, useState} from 'react';
import {Collapse, Tabs} from 'antd';
import {CaretLeft, CaretRight} from 'phosphor-react';
import {useTranslation} from 'react-i18next';

import {useLocation, useNavigate} from 'react-router-dom';
import CustomTabs from './CustomTabs';
import {useDispatch, useSelector} from 'react-redux';
import './Account.less';
import NotFound from '../NotFound';
import {useMediaQuery} from 'react-responsive';
import {AccountTabContext} from '../../utils/Context/AccountTabContext';

const CustomArrow = ({isExpanded}: {isExpanded: boolean}) =>
	isExpanded ? (
		<CaretLeft size={22} weight="thin" style={{float: 'right'}} color={'#009e83'} />
	) : (
		<CaretRight size={22} weight="thin" style={{float: 'right'}} color={'#009e83'} />
	);

const Account = () => {
	const {t} = useTranslation();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const text = queryParams.get('text');
	const name = location.pathname.slice(location.pathname.lastIndexOf('/') + 1);
	const navigate = useNavigate();
	const [activeTab, setActiveTab] = useState<string>(location.pathname);
	const isMobile = useMediaQuery({maxWidth: 870});
	const [showArrow, setShowArrow] = useState<boolean>(false);
	const [onnotificationCount, setOnnotificationCount] = useState(4);
	const auth = useSelector(({auth}) => auth.data);
	const handleTabChange = (key: string) => {
		if (activeTab === key) {
			setShowArrow(!showArrow);
		}
		navigate(key);
	};

	const handleCollapseChange = (key: string | undefined) => {
		if (key && key !== activeTab) {
			navigate(key || '');
			setActiveTab(key);
		} else {
			setActiveTab('');
		}
	};

	const paths: string[] = [];

	const items: any = CustomTabs(t)
		.filter(elem => elem.role.includes(auth.roleId))
		.map((item: any) => {
			paths.push(item.key);
			return {
				label: (
					<div className={`item-labelss ${activeTab === item.key ? 'active' : 'inactive'}`}>
						<div style={{display: 'flex', alignItems: 'center'}}>
							<img src={item.icon} alt={`tab-${item.key}`} style={{maxWidth: '24px'}} />
							<span style={{marginLeft: '10px'}} className="item-title">
								{item.title}
							</span>
						</div>

						<div>
							<div className="button-ican-notic">
								{activeTab !== item.key &&
									item.key.includes('notifications') &&
									onnotificationCount > 0 && (
										<div className="ican-notification">{onnotificationCount}</div>
									)}
								<CustomArrow isExpanded={activeTab === item.key} />
							</div>
						</div>
					</div>
				),
				children: (
					<div className={`item-content ${activeTab === item.key ? 'open' : 'inactive'}`}>
						{item.content}
					</div>
				),
				key: item.key,
				showArrow: false
			};
		});

	useEffect(() => {
		if (location.hash === '#menu') {
			setActiveTab('');
		} else {
			setActiveTab(location.pathname);
		}
	}, [location.pathname, location.hash]);
	if (!paths.includes(location.pathname) || (location.hash && location.hash !== '#menu')) {
		return <NotFound />;
	}

	return (
		<AccountTabContext.Provider value={activeTab}>
			<div className={`account-pages ${showArrow || isMobile ? 'show-arrow' : ''}`}>
				<div className="link-page-account" style={{marginTop: 32, marginBottom: 60}}>
					<span className="link-tab" onClick={() => isMobile && setActiveTab('')}>
						{auth.roleId === 4
							? t('pages.account.titleaccount')
							: t('header.loged_in_menu_partner.partnerDetails')}
					</span>
					{activeTab && <CaretRight size={14} style={{paddingTop: 4}} />}

					<span className={`link-active-tab`}>
						{activeTab &&
							(auth.roleId === 4 ? (
								name === 'complaints' ? (
									text ? (
										<span className="testtt">
											{t(`pages.account.${name}`)}

											{activeTab && <CaretRight size={12} />}
										</span>
									) : (
										`${t(`pages.account.${name}`)}`
									)
								) : (
									t(`pages.account.${name}`)
								)
							) : (
								t(`pages.account.partner.${name}.title`)
							))}
					</span>
				</div>
				<p className="acount-title">
					{auth.roleId === 4
						? t('pages.account.titleaccount')
						: t('header.loged_in_menu_partner.partnerDetails')}
				</p>
				{showArrow || isMobile ? (
					<Collapse
						accordion
						bordered={false}
						activeKey={activeTab}
						onChange={val => {
							handleCollapseChange(val[0]);
						}}
						items={items.filter((el: any) =>
							activeTab && items.findIndex((item: any) => item.key === activeTab) !== -1
								? el.key === activeTab
								: true
						)}></Collapse>
				) : (
					<div className="account-tabs">
						<Tabs
							tabPosition="left"
							activeKey={activeTab}
							onChange={handleTabChange}
							items={CustomTabs(t)
								.filter(elem => elem.role.includes(auth.roleId))
								.map(tab => ({
									key: tab.key,
									label: (
										<div className="account-tabs-div">
											<div style={{display: 'flex', alignItems: 'center'}}>
												<img
													src={tab.icon}
													alt={`tab-${tab.key}`}
													style={{maxWidth: '24px'}}
												/>
												<span className="spanss-arro">{tab.title}</span>
											</div>
											{activeTab !== tab.key && (
												<div style={{display: 'flex', alignItems: 'center'}}>
													{tab.key.includes('notifications') &&
														onnotificationCount > 0 && (
															<div className="ican-notification">{1}</div>
														)}
													<CaretRight size={22} color={'#009e83'} />
												</div>
											)}
										</div>
									),
									children: <div className="tab-content">{tab.content}</div>
								}))}
						/>
					</div>
				)}
			</div>
		</AccountTabContext.Provider>
	);
};

export default Account;
