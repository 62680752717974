import {ModalFormFieldTypesFunc} from '../../../../../utils/types';

const fields: ModalFormFieldTypesFunc = t => [
	{
		name: 'number',
		label: t('pages.account.partner.routes.routeItem.busNum'),
		type: 'select',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		},
		fetchOnDepValue: false,
		customApiFetch: {
			path: 'api/v1/routes/public',
			labelKey: val => val?.number,
			valueKey: 'number'
		}
	},
	{
		name: 'cityId',
		label: t('pages.account.partner.routes.filter.city.title'),
		type: 'select',
		placeholder: t('pages.account.partner.routes.filter.city.title'),
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		},
		dictionaryApiPath: 'cities'
	},
	{
		name: 'typeId',
		label: t('pages.account.partner.routes.filter.type.title'),
		type: 'select',
		placeholder: t('pages.account.partner.routes.filter.type.title'),
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		},
		dictionaryApiPath: 'route-types'
	},
	{
		name: 'allocationStartDate',
		label: t('pages.account.partner.routes.filter.startDateRange.title'),
		type: 'dateRange',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		}
	},
	{
		name: 'allocationEndDate',
		label: t('pages.account.partner.routes.filter.endDateRange.title'),
		type: 'dateRange',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		}
	},
	{
		name: 'statusId',
		label: t('pages.account.partner.transactions.transferstatus'),
		type: 'select',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		},
		inputProps: {
			style: {height: '50px', border: 'none'}
		},
		dictionaryApiPath: 'route-statuses'
	}

];
export default fields;
