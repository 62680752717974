import {useTranslation} from 'react-i18next';
import Toast from '../../../../../Components/Toast';
import {Button, Descriptions, DescriptionsProps} from 'antd';
import {CopySimple} from 'phosphor-react';

const BankTransfer = () => {
	let {t} = useTranslation();

	const data = {
		companyName: 'SmartSoft',
		tin: '009060429',
		accountNumber: '11715016072900',
		currency: 'ՀՀ դրամ',
		bank: 'Ամերիա բանկ',
		address: 'Կոմիտաս 27',
		city: 'Երևան',
		country: 'Հայաստան',
		postalIndex: '0041',
		purpose: '#59845#'
	};

	const handler = async (value: any) => {
		try {
			await navigator.clipboard.writeText(value);
			Toast({
				description: t('messages.copyText.title'),
				type: 'success',
				duration: 0.7,
				placement: 'topRight' // Set placement to topRight
			});
		} catch (error) {
			console.error('Failed to copy:', error);
			alert('Failed to copy to clipboard');
		}
	};

	const items: DescriptionsProps['items'] = [
		{
			key: '1',
			label: t('pages.account.myBalancePage.top.bankTransfer.table.companyName'),

			children: (
				<div className="table-children">
					<div className="table-info">{data.companyName}</div>
					<div className="table-info-copy">
						<Button onClick={() => handler(data.companyName)}>
							<CopySimple size={25} />
						</Button>
					</div>
				</div>
			)
		},
		{
			key: '2',
			label: t('pages.account.myBalancePage.top.bankTransfer.table.tin'),

			children: (
				<div className="table-children">
					<div className="table-info">{data.tin}</div>
					<div className="table-info-copy">
						<Button onClick={() => handler(data.tin)}>
							<CopySimple size={25} />
						</Button>
					</div>
				</div>
			)
		},
		{
			key: '3',
			label: t('pages.account.myBalancePage.top.bankTransfer.table.accountNumber'),
			children: (
				<div className="table-children">
					<div className="table-info">{data.accountNumber}</div>
					<div className="table-info-copy">
						<Button onClick={() => handler(data.accountNumber)}>
							<CopySimple size={25} />
						</Button>
					</div>
				</div>
			)
		},
		{
			key: '4',
			label: t('pages.account.myBalancePage.top.bankTransfer.table.currency'),
			children: (
				<div className="table-children">
					<div className="table-info">{data.currency}</div>
					<div className="table-info-copy">
						<Button onClick={() => handler(data.currency)}>
							<CopySimple size={25} />
						</Button>
					</div>
				</div>
			)
		},
		{
			key: '5',
			label: t('pages.account.myBalancePage.top.bankTransfer.table.bank'),
			children: (
				<div className="table-children">
					<div className="table-info">{data.bank}</div>
					<div className="table-info-copy">
						<Button onClick={() => handler(data.bank)}>
							<CopySimple size={25} />
						</Button>
					</div>
				</div>
			)
		},
		{
			key: '6',
			label: t('pages.account.myBalancePage.top.bankTransfer.table.address'),
			children: (
				<div className="table-children">
					<div className="table-info">{data.address}</div>
					<div className="table-info-copy">
						<Button onClick={() => handler(data.address)}>
							<CopySimple size={25} />
						</Button>
					</div>
				</div>
			)
		},
		{
			key: '7',
			label: t('pages.account.myBalancePage.top.bankTransfer.table.city'),
			children: (
				<div className="table-children">
					<div className="table-info">{data.city}</div>
					<div className="table-info-copy">
						<Button onClick={() => handler(data.city)}>
							<CopySimple size={25} />
						</Button>
					</div>
				</div>
			)
		},
		{
			key: '8',
			label: t('pages.account.myBalancePage.top.bankTransfer.table.country'),
			children: (
				<div className="table-children">
					<div className="table-info">{data.country}</div>
					<div className="table-info-copy">
						<Button onClick={() => handler(data.country)}>
							<CopySimple size={25} />
						</Button>
					</div>
				</div>
			)
		},
		{
			key: '9',
			label: t('pages.account.myBalancePage.top.bankTransfer.table.postalIndex'),
			children: (
				<div className="table-children">
					<div className="table-info">{data.postalIndex}</div>
					<div className="table-info-copy">
						<Button onClick={() => handler(data.postalIndex)}>
							<CopySimple size={25} />
						</Button>
					</div>
				</div>
			)
		},
		{
			key: '10',
			label: t('pages.account.myBalancePage.top.bankTransfer.table.purpose'),
			children: (
				<div className="table-children">
					<div className="table-info">{data.purpose}</div>
					<div className="table-info-copy">
						<Button onClick={() => handler(data.purpose)}>
							<CopySimple size={25} />
						</Button>
					</div>
				</div>
			)
		}
	];

	return (
		<div className="bank-transfer">
			<div className="bank-transfer__container">
				<div className="bank-transfer__content">
					<div className="bank-transfer__rows">
						<div className="my-balance__subheader">
							<p>{t('pages.account.myBalancePage.top.bankTransfer.chargeBalance')}</p>
						</div>
						<div className="bank-transfer__table">
							<Descriptions
								colon={false}
								title={t('pages.account.myBalancePage.top.bankTransfer.table.receiverInfo')}
								items={items}
							/>
							<p className="bank-transfer__table-description">
								<span>{t('messages.warning')}</span>

								{t('pages.account.myBalancePage.top.bankTransfer.description')}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BankTransfer;
