import {numberRules, numberRuless} from '../../../../utils/rules/validation';
import {ModalFormFieldTypesFunc} from '../../../../utils/types';

const fields: ModalFormFieldTypesFunc = t => {
	return [
		{
			name: 'chargeMoney',
			type: 'text',

			style: {
				minWidth: 90,
				height: 40
			},
			colProps: {
				xs: 8,
				lg: 6
			},

			rules: [numberRuless(t)]
		},
		{
			type: 'customElement',
			component: () => {
				return (
					<div className="myBalancePage-currency" style={{marginTop: 7}}>
						{t('pages.tickets.oneUse.ticketCard.currency')}
					</div>
				);
			}
		}
	];
};
export default fields;
