import {ModalFormFieldTypesFunc} from '../../../../utils/types';

const complaintsForm: ModalFormFieldTypesFunc = t => [
	{
		name: 'cityId',
		label: t('fields.city'),
		type: 'select',
		isRequired: false,
		colProps: {
			lg: 24,
			xs: 24
		},
		dictionaryApiPath: 'cities'
	},
	{
		name: 'typeId',
		label: t('fields.type'),
		type: 'select',

		isRequired: false,
		colProps: {
			lg: 24,
			xs: 24
		},
		dictionaryApiPath: 'complaints-types'
	},
	{
		name: 'statusId',
		label: t('pages.account.ticketHoldersPage.filter.status.title1'),
		type: 'select',
		isRequired: false,
		hiddenOptions: [1],
		colProps: {
			lg: 24,
			xs: 24
		},
		dictionaryApiPath: 'complaints-statuses'
	},

	{
		name: 'createdAt',
		isRequired: false,
		label: t('pages.account.notificationss.filter.title'),
		type: 'dateRange',

		colProps: {
			lg: 12,
			xs: 24
		}
	}
];
export default complaintsForm;
