import React, {useEffect, useState} from 'react';
import {Table, message, Empty} from 'antd';
import DataExport from '../../../../../Components/DataExport/DataExport';
import DefaultWrapper from '../../DefaultWrapper/DefaultWrapper';
import {useTranslation} from 'react-i18next';
import './styles.less';
import {useDispatch, useSelector} from 'react-redux';
import i18n from '../../../../../utils/translate/i18';
import {Link} from 'react-router-dom';
import moment from 'moment';
import PagePagination from '../../../../../Components/Pagination/PagePagination';
import {usePagination} from '../../../../../utils/usePagination/usePagination';
import {AppDispatch} from '../../../../../store';
import {getPartnerTransactions} from './../../../../../store/partner.transaction.slice';
import transactionForm from './transacxtionForm';
import dayjs from 'dayjs';
import TransactionData from './TransactionData';

const Transaction = () => {
	const {t} = useTranslation();
	const auth = useSelector((state: any) => state.auth.data);
	const dispatch = useDispatch<AppDispatch>();
	const transactions = useSelector(({transactions}) => transactions.data);
	const {currentItemsData, currentPage, goToPage} = usePagination(
		transactions.items,
		transactions.pageSize
	);
	const [info, setInfo] = useState<any>(null);

	useEffect(() => {
		dispatch(getPartnerTransactions({ page: currentPage, filter: info}));
	}, [auth.id, currentPage, info, dispatch]);

	const columns = [
		{dataIndex: 'status', title: t('pages.account.partner.transactions.transferstatus')},
		{dataIndex: 'type', title: t('pages.account.partner.transactions.transactionType')},
		{dataIndex: 'holderType', title: t('pages.tickets.oneUse.ticketCard.storage')},
		{dataIndex: 'amount', title: t('pages.account.partner.transactions.amount')},
		{dataIndex: 'createdAt', title: t('pages.account.partner.transactions.timePeriod')},
		{dataIndex: 'routeNumber', title: t('pages.account.partner.transactions.routes')},
		{dataIndex: 'validatorSerialNumber', title: t('pages.account.partner.transactions.terminal')}
	];

	const rows = currentItemsData?.map((transaction: any) => {
		return {
			status: transaction?.status ? transaction?.status[i18n.language] : ' ',
			type: transaction?.type,
			holderType: transaction?.holderType ? transaction?.holderType[i18n.language] : ' ',
			amount: transaction?.amount,
			createdAt: dayjs(transaction?.createdAt).format('DD-MM-YYYY').toString(),
			routeNumber: transaction?.routeNumber,
			validatorSerialNumber: transaction?.validatorSerialNumber
		};
	});

	return (
		<div className="transactiondata-all-div">
			<div className="transaction-data-dropdown-div">
				<div className="transaction-data-data-export">
					<DataExport exportData={{rows, columns}} />
				</div>
				<div className="transaction-data-default-wrapper">
					<DefaultWrapper
						filteritems={transactionForm}
						defaultFilters={info}
						applyFilters={(data: any) => {
							setInfo(data);
						}}
					/>
				</div>
			</div>
			{currentItemsData && currentItemsData.length > 0 ? (
				<>
					<div className="transaction-data__row">
						<div className="transaction-data">
							<div className="transaction-data-container">
								<div className="transaction-data-content">
									<div className="transaction-data-row">
										{currentItemsData?.map((elem: any) => (
											<TransactionData key={elem.id} options={elem} />
										))}
									</div>
								</div>
							</div>
						</div>
					</div>

					<div style={{marginTop: 50}}>
						<PagePagination
							pageSize={transactions.pageSize}
							currentPage={transactions.currentPage}
							totalItems={transactions.totalCount}
							onPageChange={goToPage}
						/>
					</div>
				</>
			) : (
				<div style={{padding: '50px 0px'}}>
					<Empty />
				</div>
			)}
		</div>
	);
};

export default Transaction;
