import API from '../api';
import axiosClient from '../utils/axiosClient';

const getTickets = async () => {
	const res = await axiosClient.get(API.TICKETS.tickets);
	return res?.data;
};

const TICKETS = {
	getTickets
};

export default TICKETS;
