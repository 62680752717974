import {Tabs, TabsProps} from 'antd';
import {useTranslation} from 'react-i18next';
import {useMediaQuery} from 'react-responsive';
import BankTransfer from './BankTransfer/BankTransfer';
import './MyBalance.less';
import OnlinePaymentTab from './OnlinePayment/OnlinePayment';
import {useSelector} from 'react-redux';

function MyBalance() {
	let {t} = useTranslation();

	let isMobile = useMediaQuery({maxWidth: 480});

	let auth = useSelector(({auth}) => auth.data);

	const items: TabsProps['items'] = [
		{
			key: '1',
			label: t('pages.account.myBalancePage.tabs.payOnline'),
			children: <OnlinePaymentTab />
		},
		{
			key: '2',
			label: isMobile
				? t('pages.account.myBalancePage.tabs.bankTransfer2')
				: t('pages.account.myBalancePage.tabs.bankTransfer'),
			children: <BankTransfer />
		}
	];

	return (
		<div className="my-balance">
			<div className="my-balance__container">
				<div className="my-balance__content">
					<div className="my-balance__row top-data">
						<div className="my-balance__top-data">
							<div className="top-data__rows">
								<div className="top-data__row">
									{t('pages.account.myBalancePage.top.userID', {id: auth.identityNumber})}
								</div>
								<div className="top-data__row">
									{t('pages.account.myBalancePage.top.balance', {count: auth.balance})}
								</div>
							</div>
						</div>
					</div>
					<div className="my-balance__row my-balance-tabs-main">
						<div className="my-balance__subheader">
							<p>{t('pages.account.myBalancePage.header')}</p>
						</div>
						<div className="my-balance__tabs">
							<Tabs
								className="my-balance-tabs"
								defaultActiveKey="1"
								items={items}
								onChange={() => {}}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default MyBalance;
