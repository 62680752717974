import {Divider} from 'antd';
import './RouteDrivers.less';
import {useTranslation} from 'react-i18next';
import i18n from '../../../../../../../utils/translate/i18';
import {getKeyByLang} from '../../../../../../../utils/heplerFuncs/functions';
import moment from 'moment';
import BusNumber from '../../../../../../Schedule/BusNumber/BusNumber';

const RouteDrivers = ({options}: any) => {
	let {t} = useTranslation();
	return (
		<div className="route-drivers">
			<div className="route-drivers-container">
				<div className="route-drivers-content">
					<div className="route-drivers-row">
						<div className="route-drivers__item">
							<div className="route-drivers__item-row">
								<div
									className="route-drivers__item-info"
									style={{wordWrap: 'break-word', wordBreak: 'break-all'}}>
									{options[getKeyByLang('fullName')]}
								</div>
							</div>

							<Divider style={{background: '#009E83'}} />

							<div className="route-drivers__item-row drivers-modal-top">
								<div className="route-drivers__item-main">
									<div className="route-drivers__item-title">
										{t('pages.account.partner.routes.popups.drivers.phone')}
									</div>
									<div className="route-drivers__item-info">
										{options.phoneNumber !== null ? options.phoneNumber : '-'}
									</div>
								</div>
								<div className="route-drivers__item-main">
									<div className="route-drivers__item-title">
										{t('pages.account.partner.routes.popups.drivers.march')}
									</div>
									<div className="route-drivers__item-info">
										{options?.transport?.route?.number !== null &&
										options?.transport?.route?.number !== undefined ? (
											<BusNumber number={options?.transport?.route?.number} />
										) : (
											'-'
										)}
									</div>
								</div>

								<div className="route-drivers__item-main">
									<div className="route-drivers__item-title">
										{t('pages.account.partner.buses.titles')}
									</div>
									<div className="route-drivers__item-info">
										{options?.transport?.yellowLicensePlate !== null &&
										options?.transport?.yellowLicensePlate !== undefined
											? options?.transport?.yellowLicensePlate
											: '-'}
									</div>
								</div>
								<div className="route-drivers__item-main">
									<div className="route-drivers__item-title">
										{t(
											'pages.account.partner.routes.popups.drivers.driversLicenseNumber'
										)}
									</div>
									<div className="route-drivers__item-info">
										{options?.driversLicenseNumber !== null
											? options?.driversLicenseNumber
											: '-'}
									</div>
								</div>
								<div className="route-drivers__item-main">
									<div className="route-drivers__item-title">
										{t('pages.account.partner.routes.popups.drivers.drivingLicenseDate')}
									</div>
									<div className="route-drivers__item-info">
										{moment(options.issuanceOfDriversLicense).format('DD-MM-YYYY')}
									</div>
								</div>
								<div className="route-drivers__item-main">
									<div className="route-drivers__item-title">
										{t(
											'pages.account.partner.routes.popups.drivers.drivingLicenseExpireDate'
										)}
									</div>
									<div className="route-drivers__item-info">
										{moment(options.expirationOfDriversLicense).format('DD-MM-YYYY')}
									</div>
								</div>
								<div className="route-drivers__item-main">
									<div className="route-drivers__item-title">
										{t(
											'pages.account.partner.routes.popups.drivers.employmentContractNumber'
										)}
									</div>
									<div className="route-drivers__item-info">
										{options.workContract !== null ? options.workContract : '-'}
									</div>
								</div>
								<div className="route-drivers__item-main">
									<div className="route-drivers__item-title">
										{t(
											'pages.account.ticketHoldersPage.ticketHolder.description.main.status.title'
										)}
									</div>
									<div className="route-drivers__item-info">
										{options.status && options.status[i18n.language]}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RouteDrivers;
