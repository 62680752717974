import {Button, Empty, Form, Modal} from 'antd';
import {useForm} from 'antd/es/form/Form';
import dayjs from 'dayjs';
import {Plus} from 'phosphor-react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {validateMessages} from '../../../../Components/AuthModalForm';
import DataExport from '../../../../Components/DataExport/DataExport';
import PagePagination from '../../../../Components/Pagination/PagePagination';
import Toast from '../../../../Components/Toast';
import FormGroup from '../../../../Components/formItems/FormGroup';
import {
	addNewHolderThunk,
	addNewHolderTrigger,
	getHoldersThunk,
	resetErrors
} from '../../../../store/myHolders.slice';
import {cardNumberRules} from '../../../../utils/rules/validation';
import i18n from '../../../../utils/translate/i18';
import {ModalFormFieldTypesFunc} from '../../../../utils/types';
import {usePagination} from '../../../../utils/usePagination/usePagination';
import DefaultWrapper from '../DefaultWrapper/DefaultWrapper';
import TicketHolderItem from './TicketHolderItem/TicketHolderItem';
import './TicketHolders.less';
import filter_fields from './filter.form';

const TicketHolders = () => {
	const [form] = useForm();
	const {t} = useTranslation();
	const dispatch = useDispatch<any>();
	const usersHolders = useSelector((state: any) => state.ticketHolders.data.items);
	const addNewHolderModal = useSelector(({ticketHolders}) => ticketHolders.addNewHolder);
	const formErrors = useSelector(({ticketHolders}) => ticketHolders.formErrors);
	const {currentPage, goToPage, currentItemsData} = usePagination(
		usersHolders?.items,
		usersHolders?.pageSize
	);
	const [info, setInfo] = useState(null);

	useEffect(() => {
		dispatch(getHoldersThunk({filter: info, page: currentPage}));
	}, [info, currentPage]);

	const onSubmit = async ({number}: any) => {
		await form.validateFields();
		dispatch(addNewHolderThunk(number))
			.unwrap()
			.then((res: any) => {
				Toast({type: 'success', description: t('messages.success.success')});
				dispatch(addNewHolderTrigger());
				dispatch(getHoldersThunk({filter: info, page: currentPage}));
				form.resetFields();
			})
			.catch((err: any) => {
				Toast({
					type: 'error',
					description: t('pages.account.ticketHoldersPage.filter.alreadyAttached')
				});
			});
	};

	const handlePopupCancel = () => {
		dispatch(addNewHolderTrigger());
		dispatch(resetErrors());
		form.resetFields();
	};

	const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Backspace') {
			const {value} = e.currentTarget;
			if (e.currentTarget.selectionStart === value.length && value !== '') {
				const newValue = value.slice(0, -1);
				form.setFieldsValue({
					number: newValue
				});
			}
		}
	};

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.currentTarget) {
			const {value} = e.currentTarget;
			const strippedCarrierCode = value.replace(/[^\d]/g, ''); // Remove all non-digit characters
			const formattedCarrierCode = strippedCarrierCode.replace(/(.{4})/g, '$1-'); // Format as XXXX-XXXX-XXXX-XXXX
			const truncatedCarrierCode = formattedCarrierCode.slice(0, 19); // Ensure it fits the pattern
			form.setFieldsValue({
				number: truncatedCarrierCode
			});
		}
	};

	const fields: ModalFormFieldTypesFunc = t => [
		{
			name: 'number',
			label: t('fields.format') + '(XXXX-XXXX-XXXX-XXXX)',
			type: 'text',
			placeholder: 'XXXX-XXXX-XXXX-XXXX',
			rules: [cardNumberRules(t)],
			colProps: {
				lg: 24,
				xs: 24
			},
			onKeyDown,
			onChange
		}
	];

	const columns = [
		{dataIndex: 'number', title: t('pages.account.ticketHoldersPage.filter.ticketHolderSecureCode')},
		{
			dataIndex: 'status',
			title: t('pages.account.ticketHoldersPage.ticketHolder.description.main.status.title')
		},
		{
			dataIndex: 'registrationDate',
			title: t('pages.account.ticketHoldersPage.ticketHolder.description.main.registrationDate')
		},
		{
			dataIndex: 'validDate',
			title: t('pages.account.ticketHoldersPage.ticketHolder.description.main.validDate.title')
		},
		{dataIndex: 'balance', title: t('pages.account.ticketHoldersPage.actions.checkBalance.btnTitle')},
		{dataIndex: 'kind', title: t('pages.account.ticketHoldersPage.filter.status.title')}
	];

	const rows = currentItemsData?.map((holder: any) => {
		return {
			number: holder.number,
			status: holder.status[i18n.language],
			registrationDate: dayjs(holder.registrationDate).format('DD-MM-YYYY').toString(),
			validDate: dayjs(holder.validDate).format('DD-MM-YYYY').toString(),
			balance: holder.balance,
			kind: holder.kind[i18n.language]
		};
	});

	return (
		<>
			<div className="ticket-holders">
				<div className="ticket-holders__container">
					<div className="ticket-holders__content">
						<div className="ticket-holders__top">
							<DataExport exportData={{rows, columns}} />
							<DefaultWrapper
								filteritems={filter_fields}
								defaultFilters={info}
								applyFilters={(data: any) => {
									setInfo(data);
								}}
							/>
						</div>
						<Button
							className="add-holder"
							style={{height: '57px'}}
							onClick={() => dispatch(addNewHolderTrigger())}>
							<Plus size={25} color="#009E83" weight={'bold'} />
							<p>{t('pages.account.ticketHoldersPage.actions.addTicketHolder.title')}</p>
						</Button>
						<Modal
							className="ticket-holder__popup add-ticket-holder"
							destroyOnClose
							open={addNewHolderModal}
							centered
							closeIcon={null}
							okText={t(
								'pages.account.ticketHoldersPage.actions.addTicketHolder.modal.button.add'
							)}
							title={t('pages.account.ticketHoldersPage.actions.addTicketHolder.title')}
							onOk={form.submit}
							onCancel={handlePopupCancel}>
							<Form
								form={form}
								layout="vertical"
								validateTrigger="onChange"
								validateMessages={validateMessages(t)}
								onValuesChange={() => {}}
								onFinish={onSubmit}>
								<FormGroup tabFields={fields(t)} formErrors={formErrors} form={form} />
							</Form>
						</Modal>
						{currentItemsData && currentItemsData.length > 0 ? (
							<div className="ticket-holder__items">
								{currentItemsData?.map((elem: any, index: any) => (
									<TicketHolderItem
										key={`${elem.id}-${index}`}
										options={elem}
										getbalance={elem.balance}
										ticketId={elem.id}
									/>
								))}
							</div>
						) : (
							<div style={{padding: '50px 0px'}}>
								<Empty />
							</div>
						)}
					</div>
				</div>

				<PagePagination
					pageSize={usersHolders.pageSize}
					currentPage={usersHolders.currentPage}
					totalItems={usersHolders.totalCount}
					onPageChange={goToPage}
				/>
			</div>
		</>
	);
};

export default TicketHolders;
