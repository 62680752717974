import React, {useEffect} from 'react';
import {Pagination} from 'antd';
import './PagePagination.less';
import {useLocation} from 'react-router-dom';

type PaginationT = {
	currentPage: number;
	totalItems: number;
	pageSize?: number;
	onPageChange: (page: number) => void;
};

function PagePagination({currentPage, totalItems, pageSize = 6, onPageChange}: PaginationT) {
	const handlePageChange = (page: any) => {
		onPageChange(page);
		window.scrollTo(0, 0);
	};
	let location = useLocation();
	useEffect(() => {
		onPageChange(1);
	}, [location.pathname, totalItems]);

	return (
		<div className="page-pagination">
			<Pagination
				current={currentPage}
				total={totalItems}
				pageSize={pageSize}
				onChange={handlePageChange}
			/>
		</div>
	);
}

export default PagePagination;
