import DataExport from '../../../../../Components/DataExport/DataExport';
import DefaultWrapper from '../../DefaultWrapper/DefaultWrapper';
import './Buses.less';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import filteritems from './filter.form';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch} from '../../../../../store';
import {getPartnerTransports} from '../../../../../store/partnersTransports.slice';
import {usePagination} from '../../../../../utils/usePagination/usePagination';
import PagePagination from '../../../../../Components/Pagination/PagePagination';
import RouteItemTransport from '../Routes/RouteItem/RouteItemTransport/RouteItemTransport';
import {Empty} from 'antd';
import dayjs from 'dayjs';
import i18n from '../../../../../utils/translate/i18';

function Buses() {
	let {t} = useTranslation();
	const dispatch = useDispatch<AppDispatch>();

	const buses = useSelector((state: any) => state.partnersTransport.data);
	const [info, setInfo] = useState<any>(null);

	const {currentPage, goToPage, currentItemsData} = usePagination(buses?.items, buses?.pageSize);

	useEffect(() => {
		dispatch(getPartnerTransports({page: currentPage, filter: info}));
	}, [currentPage, info, dispatch]);

	const columns = [
		{dataIndex: 'category', title: t('pages.account.partner.buses.filter.byManufacturer')},
		{dataIndex: 'city', title: t('fields.city')},
		{dataIndex: 'type', title: t('pages.account.partner.routes.popups.buses.type')},
		{dataIndex: 'licensePlate', title: t('pages.account.partner.routes.popups.buses.numberPlate')},
		{
			dataIndex: 'yellowLicensePlate',
			title: t('pages.account.partner.routes.popups.buses.yellowNumberPlate')
		},
		{
			dataIndex: 'yellowLicensePlateReceiptDate',
			title: t('pages.account.partner.routes.popups.buses.yellowNumberPlateData')
		},
		{
			dataIndex: 'yellowLicensePlateDeadline',
			title: t('pages.account.partner.routes.popups.buses.yellowNumberPlateData1')
		},
		{dataIndex: 'releaseDate', title: t('pages.account.partner.routes.popups.buses.productionDate')},
		{dataIndex: 'numberOfSeats', title: t('pages.account.partner.routes.popups.buses.numberOfSeats')},
		{
			dataIndex: 'numberOfStandingPassengers',
			title: t('pages.account.partner.routes.popups.buses.standingPassengersCount')
		},
		{dataIndex: 'fuelType ', title: t('pages.account.partner.routes.popups.buses.fuelType')},
		{dataIndex: 'validator.serialNumber', title: t('pages.account.partner.transactions.terminal')},
		{dataIndex: 'route.number', title: t('pages.account.partner.routes.routeItem.marchName')},
		{
			dataIndex: 'status',
			title: t('pages.account.ticketHoldersPage.ticketHolder.description.main.status.title')
		}
	];
	const rows = currentItemsData?.map((transports: any) => {
		return {
			category: transports?.category ? transports?.category[i18n.language] : ' ',
			city: transports?.city ? transports?.city[i18n.language] : ' ',
			type: transports?.type ? transports?.type[i18n.language] : ' ',
			licensePlate: transports?.licensePlate || ' ',
			yellowLicensePlate: transports?.yellowLicensePlate || ' ',
			yellowLicensePlateReceiptDate: transports?.yellowLicensePlateReceiptDate
				? dayjs(transports.yellowLicensePlateReceiptDate).format('DD-MM-YYYY').toString()
				: '-',
			yellowLicensePlateDeadline: transports?.yellowLicensePlateDeadline
				? dayjs(transports.yellowLicensePlateDeadline).format('DD-MM-YYYY').toString()
				: '-',
			releaseDate: transports?.releaseDate
				? dayjs(transports.releaseDate).format('DD-MM-YYYY').toString()
				: '-',
			numberOfSeats: transports?.numberOfSeats || '-',
			numberOfStandingPassengers: transports?.numberOfStandingPassengers || '-',
			fuelType: transports?.fuelType || '-',
			'validator.serialNumber': transports?.validator?.serialNumber || '-',
			'route.number': transports?.route?.number || '-',
			status: transports?.status ? transports.status[i18n.language] : '-'
		};
	});

	return (
		<div className="partner-buses">
			<div className="partner-buses__container">
				<div className="partner-buses__content">
					<div className="partner-buses__top">
						<DataExport exportData={{rows, columns}} />
						<DefaultWrapper
							filteritems={filteritems}
							defaultFilters={info}
							applyFilters={(data: any) => {
								setInfo(data);
								goToPage(1);
							}}
						/>
					</div>
					{buses?.items && buses.items.length > 0 ? (
						<>
							<div className="partner-buses__row">
								<div className="route-item-transport">
									<div className="route-item-transport__container">
										<div className="route-item-transport__content">
											{currentItemsData.map((elem: any) => (
												<RouteItemTransport key={elem.id} options={elem} />
											))}
										</div>
									</div>
								</div>
							</div>
							<PagePagination
								pageSize={buses.pageSize}
								currentPage={buses.currentPage}
								totalItems={buses.totalCount}
								onPageChange={goToPage}
							/>
						</>
					) : (
						<div style={{padding: '50px 0'}}>
							<Empty description={t('No buses available')} />
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default Buses;
