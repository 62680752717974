import API from '../api';
import axiosClient from '../utils/axiosClient';
const getBanner = async () => {
	const res = await axiosClient.get(API.BANNER.banner, {params: {orderBy: 'sequence'}});
	return res?.data;
};

const BANNER = {
	getBanner
};

export default BANNER;
