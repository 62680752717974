import {CaretLeft} from 'phosphor-react';
import {setAuthModalVisible} from '../../../store/auth.slice';
import {ModalFormFieldTypes, TranslationFuncType} from '../../../utils/types';
import {latinRulesWithNumber} from '../../../utils/rules/validation';
import ConfirmPassword from '../components/ConfirmPassword';

interface ForgotPassTypes {
	(t: TranslationFuncType, dispatch: (data: any) => void, isSuccess?: boolean): ModalFormFieldTypes[];
}

const fields: ForgotPassTypes = (t, dispatch, isSuccess) => [
	...(isSuccess
		? [
				{
					type: 'customElement',
					component: () => <ConfirmPassword dispatch={dispatch} />,
					colProps: {
						xs: 24
					}
				}
		  ]
		: [
				{
					label: t('fields.email'),
					name: 'email',
					type: 'text',
					rules: [{type: 'email'}, latinRulesWithNumber(t)],
					colProps: {
						xs: 24
					}
				},
				{
					type: 'customElement',
					component: () => {
						return (
							<div className="auth-component__forgotPass">
								<div
									className="auth-component__forgotPass-main"
									onClick={() => {
										dispatch(setAuthModalVisible({visible: 'SIGN_IN'}));
									}}>
									<CaretLeft size={22} />
									<span>{t('header.back')}</span>
								</div>
							</div>
						);
					},
					colProps: {
						xs: 24
					}
				}
		  ])
];
export default fields;
