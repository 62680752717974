import {ModalFormFieldTypesFunc} from '../../../../../utils/types';

const fields: ModalFormFieldTypesFunc = t => [
	{
		name: 'routeCityId',
		label: t('pages.account.partner.routes.filter.city.title'),
		type: 'select',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		},
		dictionaryApiPath: 'cities'
	},

	{
		name: 'typeId',
		label: t('pages.account.partner.routes.filter.type.title'),
		type: 'select',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		},
		dictionaryApiPath: 'transport-types'
	},
	{
		name: 'categoryId',
		label: t('pages.account.partner.buses.filter.byManufacturer'),
		type: 'select',
		isRequired: false,
		selectProps: {
			options: [
				{
					label: 'Գազել',
					value: '2'
				},
				{
					label: 'Մերսեդես',
					value: '1'
				}
			]
		},
		colProps: {
			lg: 12,
			xs: 24
		}
	},
	{
		name: 'routeNumber',
		isRequired: false,
		label: t('pages.account.historyMarches.byroute'),
		type: 'select',
		allowClear: true,
		colProps: {
			lg: 24,
			xs: 24
		},
		fetchOnDepValue: false,
		customApiFetch: {
			path: 'api/v1/routes/public',
			labelKey: val => val?.number,
			valueKey: 'number'
		}
	},
	{
		name: 'licensePlate',
		label: t('pages.account.partner.routes.popups.buses.numberPlate'),
		type: 'text',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		},
		inputProps: {
			style: {height: '50px', border: 'none'}
		}
	},
	{
		name: 'yellowLicensePlate',
		label: t('pages.account.partner.routes.popups.buses.yellowNumberPlate'),
		type: 'text',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		},
		inputProps: {
			style: {height: '50px', border: 'none'}
		}
	},
	{
		name: 'yellowLicensePlateReceiptDate',
		label: t('pages.account.partner.routes.popups.buses.yellowNumberPlateData'),
		type: 'dateRange',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		}
	},
	{
		name: 'yellowLicensePlateDeadline',
		label: t('pages.account.partner.routes.popups.buses.yellowNumberPlateData1'),
		type: 'dateRange',
		isRequired: false,
		colProps: {
			lg: 12,
			xs: 24
		}
	}
];
export default fields;
