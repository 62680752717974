import {useTranslation} from 'react-i18next';
import i18n from '../../../../../utils/translate/i18';

const TransfersHistoryData = ({options}: any) => {
	const {t} = useTranslation();

	const getTagColor = (statusId: number) => {
		switch (statusId) {
			case 1:
				return '#009e83';
			case 2:
				return '#9E9E9E';
			default:
				return 'black';
		}
	};

	return (
		<div className="transfers-history-data">
			<div className="transfers-history-data-container">
				<div className="transfers-history-data-content">
					<div className="transfers-history-data-row">
						<div className="transfers-history-data__item">
							<div className="transfers-history-data__item-row">
								<div className="transfers-history-box-data">
									{/* {options[`createdAt_${i18n.language}`]} */}
								</div>
							</div>
							<div className="transfers-history-data__item-row drivers-modal-top">
								<div className="transfers-history-data__item-main">
									<div className="transfers-history-data__item-title">
										{t('pages.account.partner.transfersHistory.transferStatus')}
									</div>
									<div
										className="transfers-history-data__item-info"
										style={{color: getTagColor(options.statusID)}}>
										{/* {t(`statuses1.${options.statusID}`)} */}
									</div>
								</div>
								<div className="transfers-history-data__item-main">
									<div className="transfers-history-data__item-title">
										{t('pages.account.partner.transfersHistory.times')}
									</div>
									<div className="transfers-history-data__item-info">
										{/* {options.timeSegment} */}

										{/* {options.timeSegmentY} */}
									</div>
								</div>
								<div className="transfers-history-data__item-main">
									<div className="transfers-history-data__item-title">
										{t('pages.account.partner.transfersHistory.totalamount')}
									</div>
									<div
										className="transfers-history-data__item-info"
										style={{color: getTagColor(options.statusID)}}>
										{/* {options.totalAmount} {t('AMD')} */}
									</div>
								</div>
								<div className="transfers-history-data__item-main">
									<div className="transfers-history-data__item-title">
										{t('pages.account.partner.transfersHistory.transferNumber')}
									</div>
									<div className="transfers-history-data__item-info">
										{/* {options.number} */}

									</div>
								</div>
								<div className="transfers-history-data__item-main">
									<div className="transfers-history-data__item-title">
										{t('pages.account.partner.transfersHistory.notice')}
									</div>
									<div className="transfers-history-data__item-info">
										{/* {options[`notification_${i18n.language}`]} */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TransfersHistoryData;
