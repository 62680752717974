import {Button} from 'antd';
import {
	internationalPhoneNumberRule,
	latinRulesWithNumber,
	numberRules
} from '../../../../utils/rules/validation';
import {ModalFormFieldTypesFunc} from '../../../../utils/types';
import dayjs from 'dayjs';
import helperFuncs from '../../../../utils/heplerFuncs/functions';
import i18next from 'i18next';

const fields: ModalFormFieldTypesFunc = t => [
	{
		name: 'firstName',
		label: t('fields.name'),
		type: 'text',
		colProps: {xs: 24, lg: 24}
	},
	{
		name: 'lastName',
		label: t('fields.surname'),
		type: 'text',
		colProps: {xs: 24, lg: 24}
	},
	{
		name: 'email',
		label: t('fields.email'),
		type: 'text',
		inputProps: {
			disabled: true
		},
		rules: [{type: 'email'}, latinRulesWithNumber(t)],
		colProps: {xs: 24, lg: 24}
	},
	{
		name: 'phoneNumber',
		label: t('fields.phone'),
		inputProps: {addonBefore: '+'},
		type: 'phone',
		rules: [internationalPhoneNumberRule(t)],
		colProps: {xs: 24, lg: 24}
	},
	{
		name: 'genderId',
		label: t('fields.gender'),
		type: 'select',
		colProps: {
			xs: 24,
			lg: 24
		},
		dictionaryApiPath: 'genders',
		style: {
			height: 51
		}
	},

	{
		name: 'dob',
		label: t('fields.dob'),
		type: 'date',
		colProps: {
			xs: 24,
			lg: 24
		},
		datePickerProps: {
			format: 'DD-MM-YYYY',
			disabledDate: (current: any) => {
				let customDate = dayjs().subtract(0, 'day').subtract(7, 'year');
				return current && current.isAfter(customDate);
			}
		}
	}
];

export default fields;
