import {ModalFormFieldTypesFunc} from '../../../../utils/types';

const fields: ModalFormFieldTypesFunc = t => [
	{
		name: 'asda',
		placeholder: t('pages.account.ticketHoldersPage.actions.moveBalance.selects.placeholder'),
		label: t('pages.account.ticketHoldersPage.actions.moveBalance.selects.from'),
		type: 'select',
		showSearch: false,
		selectProps: {
			options: [
				{
					label: '1234 5678 9000 0037',
					value: '1234 5678 9000 0037'
				},
				{
					label: '7514 5678 9000 8837',
					value: '7514 5678 9000 881232'
				}
			]
		},
		style: {
			height: 50
		},

		colProps: {
			lg: 24
		}
	},
	{
		name: 'asdasdda',
		placeholder: t('pages.account.ticketHoldersPage.actions.moveBalance.selects.placeholder'),
		label: t('pages.account.ticketHoldersPage.actions.moveBalance.selects.to'),
		type: 'select',
		showSearch: false,
		selectProps: {
			options: [
				{
					label: '7514 5678 9000 8837',
					value: '7514 5678 9000 8837'
				},
				{
					label: '7514 5678 9000 8837',
					value: '7514 5678 9000 883712'
				}
			]
		},
		colProps: {
			lg: 24
		},
		style: {
			height: 50,
			color: 'red'
		}
	}
];
export default fields;
