import {Button, Collapse, Empty, Form, Modal} from 'antd';
import moment from 'moment';
import {CaretLeft, CaretRight, Plus} from 'phosphor-react';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useMediaQuery} from 'react-responsive';
import {useLocation, useNavigate} from 'react-router-dom';
import {validateMessages} from '../../../../Components/AuthModalForm';
import FormGroup from '../../../../Components/formItems/FormGroup';
import PagePagination from '../../../../Components/Pagination/PagePagination';
import Toast from '../../../../Components/Toast';
import SERVICES from '../../../../services';
import {addComplaintModalTrigger, getUserComplaints} from '../../../../store/complaints.slic';
import i18n from '../../../../utils/translate/i18';
import {usePagination} from '../../../../utils/usePagination/usePagination';
import DefaultWrapper from '../DefaultWrapper/DefaultWrapper';
import './Complaints.less';
import complaintsForm from './complaintsForm.';
import new_complaint_fields from './sendNewComplaint.form';

const CustomArrow = ({isExpanded}: any) => (
	<div>
		{isExpanded ? (
			<CaretLeft size={22} weight="thin" color="#009e83" style={{float: 'right'}} />
		) : (
			<CaretRight size={22} weight="thin" color="#009e83" style={{float: 'right'}} />
		)}
	</div>
);

const Complaints = () => {
	const [activePanel, setActivePanel] = useState<string | string[]>([]);
	const [active, setActive] = useState<string | null>();
	const addComplaintModal = useSelector(({complaints}) => complaints.addComplaintModal);
	const complaints = useSelector(({complaints}) => complaints.data.items);
	const {data} = useSelector(({complaints}) => complaints);
	const auth = useSelector(({auth}) => auth.data);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const {currentPage, goToPage} = usePagination(complaints.pageSize);
	const [info, setInfo] = useState<any>(null);
	const [expandedMessages, setExpandedMessages] = useState<{[key: string]: boolean}>({});
	const [uploadError, setUploadError] = useState<string | null>(null);
	let location = useLocation();
	const isMobile = useMediaQuery({maxWidth: 480});
	let [form] = Form.useForm();
	const formErrors = useSelector((state: any) => state.complaints.formErrors);

	useEffect(() => {
		dispatch(getUserComplaints({page: currentPage, filter: info}));
	}, [currentPage, info]);

	useEffect(() => {
		return () => {
			setActivePanel([]);
			setActive(null);
		};
	}, [location.pathname]);

	const handleCollapseChange = (key: string | string[]) => {
		setActivePanel(key);
		setActive(Array.isArray(key) ? key[0] : key);

		if (Array.isArray(key) && key.length > 0) {
			const firstKey = key[0];
			const clickedComplaint = complaints.find((item: any) => item.key === firstKey);
			if (clickedComplaint) {
				const languageField = `productName_${i18n.language}`;
				const text = clickedComplaint[languageField];
				if (text != null && text !== undefined && text !== '') {
					navigate(`/account/complaints?text=${encodeURIComponent(text)}`);
				}
			}
		}

		window.scrollTo({top: 0, behavior: 'smooth'});
	};

	const toggleMessageExpand = (key: string) => {
		setExpandedMessages(prevExpandedMessages => ({
			...prevExpandedMessages,
			[key]: !prevExpandedMessages[key]
		}));
	};

	const items: any = complaints.map((item: any, index: number) => {
		const messagesToRender: any = [];

		// Filter messages by sender and text existence
		const sender0Messages = item.msgs.filter((msg: any) => msg.sender === 0 && msg.text);
		const sender1Messages = item.msgs.filter(
			(msg: any) => (msg.sender === 1 && msg.text) || (msg.sender === 1 && msg.answerAttachedFile)
		);
		// Add sender === 0 messages
		sender0Messages.forEach((msg: any, msgIndex: number) => {
			const messageStyle: React.CSSProperties = {
				alignSelf: 'flex-end',
				borderRadius: msgIndex === 0 ? '10px 10px 0px 10px' : '10px 10px 10px 0px',
				textAlign: isMobile ? 'left' : 'right'
			};

			messagesToRender.push(
				<div key={`sender0_${msgIndex}`} className="maseges-text-exp" style={messageStyle}>
					<div className="header-maseges">
						<span className="senderName">{msg[`name_${i18n.language}`]}</span>
						<span className="data-munth-year">
							<span>{moment(msg.createdAt).format('DD')} </span>
							<span>{t(`months.${moment(msg.createdAt).month()}`)} </span>
							<span>{moment(msg.createdAt).format('YYYY')} </span>
							<span>{moment(msg.createdAt).format('HH:mm')} </span>
						</span>
					</div>
					<div className="maseges-text-msg">
						{msg.text?.length > 200 && !expandedMessages[`sender0_${msgIndex}`] ? (
							<div>
								{msg.text.slice(0, 200)}...
								<p
									className="read-more"
									onClick={() => toggleMessageExpand(`sender0_${msgIndex}`)}>
									{t('complaints.readMore')}
								</p>
								<p>
									{msg?.attachedFile !== null && (
										<>
											<a
												style={{
													textDecoration: 'underline',
													color: '#009e83'
												}}
												href={msg.attachedFile}
												target="_blank"
												rel="noopener noreferrer">
												{t('complaints.attachedfile')}
											</a>
										</>
									)}
								</p>
							</div>
						) : (
							<div>
								<p style={{wordWrap: 'break-word'}}>{msg.text}</p>

								{msg.text?.length > 200 && expandedMessages[`sender0_${msgIndex}`] && (
									<p
										className="read-more"
										onClick={() => toggleMessageExpand(`sender0_${msgIndex}`)}>
										{t('complaints.readless')}
									</p>
								)}
								<p>
									{msg?.attachedFile !== null && (
										<>
											<a
												style={{
													textDecoration: 'underline',
													color: '#009e83'
												}}
												href={msg.attachedFile}
												target="_blank"
												rel="noopener noreferrer">
												{t('complaints.attachedfile')}
											</a>
										</>
									)}
								</p>
							</div>
						)}
					</div>
				</div>
			);
		});

		// Add sender === 1 messages
		sender1Messages.forEach((msg: any, msgIndex: number) => {
			const messageStyle: React.CSSProperties = {
				alignSelf: 'flex-start',
				borderRadius: msgIndex === 0 ? '10px 10px 10px 0px' : '10px 10px 10px 0px',
				textAlign: 'left'
			};
			messagesToRender.push(
				<div key={`sender1_${msgIndex}`} className="maseges-text-exp" style={messageStyle}>
					<div className="header-maseges">
						<span className="senderName">{msg[`name_${i18n.language}`]}</span>
						<span className="data-munth-year">
							<span>{moment(msg.answeredAt).format('DD')} </span>
							<span>{t(`months.${moment(msg.answeredAt).month()}`)} </span>
							<span>{moment(msg.answeredAt).format('YYYY')} </span>
							<span>{moment(msg.answeredAt).format('HH:mm')} </span>
						</span>
					</div>
					<div className="maseges-text-msg">
						{msg.text?.length > 200 && !expandedMessages[`sender1_${msgIndex}`] ? (
							<div>
								{msg.text.slice(0, 200)}...
								<p
									className="read-more"
									onClick={() => toggleMessageExpand(`sender1_${msgIndex}`)}>
									{t('complaints.readMore')}
								</p>
								<p>
									{msg?.answerAttachedFile !== null && (
										<>
											<a
												style={{
													textDecoration: 'underline',
													color: '#009e83'
												}}
												href={msg.answerAttachedFile}
												target="_blank"
												rel="noopener noreferrer">
												{t('complaints.attachedfile')}
											</a>
										</>
									)}
								</p>
							</div>
						) : (
							<div>
								<p style={{wordWrap: 'break-word'}}>{msg.text}</p>

								{msg.text?.length > 200 && expandedMessages[`sender1_${msgIndex}`] && (
									<p
										className="read-more"
										onClick={() => toggleMessageExpand(`sender1_${msgIndex}`)}>
										{t('complaints.readless')}
									</p>
								)}
								<p>
									{msg?.answerAttachedFile !== null && (
										<>
											<a
												style={{
													textDecoration: 'underline',
													color: '#009e83'
												}}
												href={msg.answerAttachedFile}
												target="_blank"
												rel="noopener noreferrer">
												{t('complaints.attachedfile')}
											</a>
										</>
									)}
								</p>
							</div>
						)}
					</div>
				</div>
			);
		});
		const getStatusText = (statusId: any) => {
			if (statusId === 'Ընդունված') {
				return 'Ընթացքի մեջ';
			}
			return statusId || '';
		};
		return {
			label: (
				<>
					<div className="label">
						<div style={{display: 'flex', alignItems: 'center', alignSelf: 'center'}}>
							<div>
								<span className="lable-createdAd">
									{moment(item.createdAt).format('DD-MM-YYYY HH:mm')}
								</span>
								<p className="lable-createdAd" style={{margin: 0, color: '#009e83'}}>
									{item.typeId && item.typeId[i18n.language]}
								</p>
							</div>
							<button className="lable-status">
								{item.statusId && getStatusText(item.statusId[i18n.language])}
							</button>
						</div>
						<p className="lable-text" style={{wordWrap: 'break-word'}}>
							{item[`productName_${i18n.language}`]}
						</p>
					</div>
				</>
			),
			children: (
				<div>
					<div className="complaints-item-content">{messagesToRender}</div>
					<div className="errormeseges"> {uploadError}</div>
				</div>
			),
			key: item.key
		};
	});

	const handleComplaintSend = async (data: any) => {
		const extraData = {
			...data,
			customerEmail: auth.email,
			customerFirstName: auth.firstName,
			customerLastName: auth.lastName,
			customerPhoneNumber: auth.phoneNumber
		};

		try {
			await form.validateFields();
			await SERVICES.COMPLAINTS.createComplaint(extraData);
			Toast({
				type: 'success',
				description: t('messages.success.success')
			});
			dispatch(addComplaintModalTrigger());
			form.resetFields();

			dispatch(getUserComplaints({page: currentPage, filter: info}));
		} catch (err) {
			Toast({
				type: 'error',
				description: t('messages.error.badRequest')
			});
		}
	};
	return (
		<div className="complaints-box">
			{active ? null : (
				<div className="notification-form">
					<div className="add-complaint">
						<Button
							onClick={() => dispatch(addComplaintModalTrigger())}
							className="add-complaint-button"
							icon={<Plus size={30} className="complaint__plus-icon" />}
						/>

						<Modal
							open={addComplaintModal}
							centered
							className="add-complaint-modal"
							title={t('pages.account.complaintsPage.сomplaint')}
							closeIcon={false}
							footer={false}
							onCancel={() => {
								dispatch(addComplaintModalTrigger());

								form.resetFields();
							}}>
							<Form
								form={form}
								layout="vertical"
								validateMessages={validateMessages(t)}
								validateTrigger="onChange"
								onFinish={handleComplaintSend}>
								<FormGroup
									tabFields={new_complaint_fields(t)}
									formErrors={formErrors}
									form={form}
								/>
							</Form>
							<div className="account__report__modal">
								<div className="account__report__cancel">
									<Button
										size="large"
										onClick={() => {
											dispatch(addComplaintModalTrigger());

											form.resetFields();
										}}>
										{t('actions.cancel')}
									</Button>
								</div>
								<div className="account__report__submit">
									<Button size="large" onClick={form.submit}>
										{t('actions.send')}
									</Button>
								</div>
							</div>
						</Modal>
					</div>
					<DefaultWrapper
						filteritems={complaintsForm}
						defaultFilters={info}
						applyFilters={(data: any) => {
							setInfo(data);
						}}
					/>
				</div>
			)}
			<div className="complaints">
				{complaints[0] ? (
					<Collapse
						accordion
						className="complaints_collaps"
						bordered={false}
						activeKey={activePanel}
						onChange={handleCollapseChange}
						expandIcon={({isActive}) => <CustomArrow isExpanded={isActive} />}
						items={items.filter((el: any) => (active ? el.key === active : true))}></Collapse>
				) : (
					<Empty />
				)}
			</div>
			<div style={{marginTop: 50}}>
				{active ? null : (
					<PagePagination
						pageSize={data.pageSize}
						currentPage={data.currentPage}
						totalItems={data.totalCount}
						onPageChange={goToPage}
					/>
				)}
			</div>
		</div>
	);
};

export default Complaints;
