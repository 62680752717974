import React from 'react';
import './style.less';
import {WarningCircle} from 'phosphor-react';
import {Button} from 'antd';
import {useTranslation} from 'react-i18next';
import {setAuthModalVisible} from '../../../store/auth.slice';

interface ConfirmPasswordProps {
	dispatch: (data: any) => void;
}

const ConfirmPassword = ({dispatch}: ConfirmPasswordProps) => {
	const {t} = useTranslation();
	return (
		<div className="confirmPassword-box-all">
			<div className="confirmPassword-box">
				<div className="confirmPassword">
					<p style={{padding: 0, margin: 0}}>
						<WarningCircle size={62} color="#009E83" weight="fill" />
					</p>
					<p className="title">{t('confirmPassword.title')} </p>
					<p className="text">{t('confirmPassword.text')}</p>
				</div>
			</div>
		</div>
	);
};

export default ConfirmPassword;
